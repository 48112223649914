import { Document, Font, Image, Page, PDFDownloadLink, StyleSheet, Text, View } from '@react-pdf/renderer';
import { Box, makeStyles, Typography } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import JsBarcode from 'jsbarcode';
import { toast } from 'react-toastify';

import { AuthContext } from '../../auth';
import { BabacoTheme } from '../../styles/theme';
import { formatDate } from '../../utils/helpers';
import { FileDownload } from 'mdi-material-ui';
import Roboto from '../../styles/fonts/Roboto-Regular.ttf';
import RobotoBold from '../../styles/fonts/Roboto-Bold.ttf';
import { get } from '../../utils/axios';

const useStyles = makeStyles(() => ({
  box: {
    border: '1px solid',
    padding: BabacoTheme.spacing.sm,
    width: 'fit-content',
    maxWidth: '400px',
  },
  boxWrapper: {
    width: 'fit-content',
    display: 'flex',
    flexDirection: 'column',
  },
  pdfLink: {
    marginLeft: 'auto',
    cursor: 'pointer',
  },
  listText: {
    lineHeight: 1.2,
    fontSize: '16px',
    wordWrap: 'break-word',
  },
  issuesHeading: {
    lineHeight: 1.2,
    fontSize: '16px',
    marginRight: '4px',
  },
  pdfHeader: {
    fontSize: '24px',
    lineHeight: 1.3,
    wordWrap: 'break-word',
  },
  boxesWrapper: {
    display: 'flex',
  },
  red: { color: 'red' },
  bulletItem: {
    '&:before': {
      display: 'inline-block',
      content: '""',
      borderRadius: '2rem',
      height: '0.25rem',
      width: '0.25rem',
      marginRight: '0.5rem',
      backgroundColor: 'black',
      position: 'relative',
      bottom: '3px',
      wordWrap: 'break-word',
    },
  },
  bolImg: {
    width: '100%',
    objectFit: 'contain',
    border: '1px solid gray',
  },
  issues: {
    display: 'flex',
  },
}));

Font.register({ family: 'Roboto', src: Roboto });
Font.register({ family: 'RobotoBold', src: RobotoBold });

const pdfStyles = StyleSheet.create({
  page: {
    width: '100%',
  },
  mainView: {
    padding: '12px 12px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  mainViewSingle: {
    padding: '12px 12px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  section: {
    margin: 10,
    padding: 10,
    border: '1px solid black',
    width: '40%',
  },
  title: {
    fontSize: 18,
    fontFamily: 'RobotoBold',
    marginBottom: '8px',
    paddingBottom: '8px',
    borderBottom: '5px solid black',
    whiteSpace: 'wrap',
  },
  subtitle: {
    fontSize: 16,
    fontFamily: 'RobotoBold',
    marginBottom: '16px',
  },
  text: {
    fontSize: 12,
    fontFamily: 'Roboto',
    lineHeight: 1.2,
    wordWrap: 'break-word',
  },
  textDate: {
    fontSize: 12,
    fontFamily: 'Roboto',
    lineHeight: 1.2,
    marginRight: 3,
  },
  dateView: {
    display: 'flex',
    flexDirection: 'row',
  },
  labelWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  label: {
    fontSize: 12,
    fontFamily: 'Roboto',
    lineHeight: 1.2,
    marginTop: '8px',
    wordWrap: 'break-word',
  },
  imageLabel: {
    fontSize: 10,
    fontFamily: 'Roboto',
    lineHeight: 1.2,
    marginTop: '4px',
    wordWrap: 'break-word',
  },
  image: {
    marginTop: '16px',
    objectFit: 'contain',
    width: '100%',
    padding: 1,
    border: '1px solid black',
    backgroundColor: 'black',
  },
  imageBarcode: {
    marginTop: '24px',
    height: 150,
  },
  bolImg: {
    width: '100%',
  },
  red: {
    color: 'red',
  },
  issues: {
    display: 'flex',
    flexDirection: 'row',
  },
  issuesHeading: {
    lineHeight: 1.2,
    fontSize: '13px',
    marginRight: '4px',
    fontWeight: 400,
  },
});

const PageBolDetails = () => {
  const classes = useStyles();
  const { user } = useContext(AuthContext);

  const [bol, setBol] = useState(null);

  const params: any = useParams();

  const canvas = document.createElement('canvas');
  JsBarcode(canvas, bol?.code, { displayValue: false });
  const barcode = canvas.toDataURL();

  const fetchBol = async () => {
    try {
      const { data } = await get(`/bols/${params.id}`);

      setBol(data);
    } catch (error) {
      toast.error(error?.response?.data?.message, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  useEffect(() => {
    fetchBol();
  }, []);

  const outboundDate = bol?.outbound?.date && formatDate(bol?.outbound?.date, bol?.outbound?.creator?.timezone, true);
  const inboundDate = bol && formatDate(bol?.inbound?.date, bol?.inbound?.creator?.timezone, true);

  const PDFPage = ({ bol, user, barcode }: any) => (
    <Document>
      <Page style={pdfStyles.page}>
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignContent: 'center',
            backgroundColor: 'black',
            padding: '30 0',
            marginBottom: 16,
          }}
        >
          <Image src="/assets/babaco-logo.png" style={{ height: '', objectFit: 'cover', width: '300' }} />
        </View>
        <View style={bol?.inbound?.date ? pdfStyles.mainView : pdfStyles.mainViewSingle}>
          <View style={pdfStyles.section}>
            <Text style={pdfStyles.title}>Record# {bol.code}</Text>
            <Text style={pdfStyles.subtitle}>OUTBOUND</Text>
            <Text style={pdfStyles.text}>{bol.outbound?.creator?.firstName}</Text>
            <Text style={pdfStyles.textDate}>{outboundDate}</Text>
            {renderFieldPDF(bol?.outbound?.values, user?.form, false, true)}

            <Image src={barcode} style={pdfStyles.imageBarcode} />
          </View>
          {bol?.inbound?.date && (
            <View style={pdfStyles.section}>
              <Text style={pdfStyles.title}>Record# {bol.code}</Text>
              <Text style={pdfStyles.subtitle}>INBOUND</Text>
              <Text style={pdfStyles.text}>{bol.inbound?.creator?.firstName}</Text>
              <Text style={pdfStyles.textDate}>{inboundDate}</Text>
              {renderFieldPDF(bol?.inbound?.values, user?.form)}

              <View style={pdfStyles.issues}>
                {bol?.type === 'inbound_only' && bol?.differences?.length > 0 && (
                  <Text style={pdfStyles.issuesHeading}>Issues:</Text>
                )}
                {bol?.type === 'inbound_only' && (
                  <Text style={[pdfStyles.text, pdfStyles.red]}>{bol?.differences?.join(', ')}</Text>
                )}
              </View>

              <Image src={barcode} style={pdfStyles.imageBarcode} />
            </View>
          )}
        </View>

        <View
          style={{
            backgroundColor: 'black',
            padding: '16px 0',
            display: 'flex',
            justifyContent: 'center',
            alignContent: 'center',
            textAlign: 'center',
            marginTop: 'auto',
          }}
        />
      </Page>
    </Document>
  );

  const generatePDFDownloadLink = () => {
    return (
      <PDFDownloadLink
        document={<PDFPage bol={bol} user={user} barcode={barcode} />}
        fileName={`trucksync_${bol.code}.pdf`}
        className={classes.pdfLink}
      >
        {() => <FileDownload className="mb-3" />}
      </PDFDownloadLink>
    );
  };

  const renderFieldPDF = (values, form, parent = false, outbound = false) => {
    return form?.map((item) => {
      const value = values?.[item.name];
      const opposite = outbound ? bol?.inbound?.values?.[item.name] : bol?.outbound?.values?.[item.name];

      let shouldShowAlert =
        bol?.inbound?.date && bol?.outbound?.values?.[item.name] !== bol?.inbound?.values?.[item.name];

      if (bol?.type === 'inbound_only') shouldShowAlert = false;

      if (bol?.type === 'inbound_only' && !bol?.outbound?.values?.[item?.name]?.length && outbound) return null;

      if (!item?.mandatory && item.type !== 'object' && !values?.[item?.name]?.length) return null;

      if (!!value && !item.visible) return null;

      if (!value) return null;

      switch (item.type) {
        case 'images':
          let different = value?.length !== opposite?.length;

          value.forEach((image, imageIdx) => {
            if (image?.uri !== opposite?.[imageIdx]?.uri || image?.label !== opposite?.[imageIdx]?.label) {
              different = true;
            }
          });

          return (
            <View key={item.name}>
              <View style={pdfStyles.labelWrapper}>
                <Text style={{ ...pdfStyles.text, color: different ? 'red' : 'black' }}>
                  {parent && '- '}
                  {item.name}: {value?.length} Photo{value?.length > 1 && 's'} Uploaded
                </Text>
              </View>
              {value?.map((el: any, idx: number) => {
                const differentLabel = el?.label !== opposite?.[idx]?.label;
                return (
                  <View key={idx} style={{ marginBottom: '6px', marginTop: '6px' }}>
                    <Image style={pdfStyles.image} src={el.uri || ''} />
                    <Text style={{ ...pdfStyles.imageLabel, color: differentLabel ? 'red' : 'black' }}>
                      {item.name}&nbsp;-&nbsp;{el?.label || `Photo ${idx + 1}`}
                    </Text>
                  </View>
                );
              })}
            </View>
          );

        case 'object':
          return (
            <View key={item.name} style={{ marginBottom: '8px' }}>
              <Text style={pdfStyles.label}>{item.name}:</Text>
              {renderFieldPDF(value, item.children, true, outbound)}
            </View>
          );

        default:
          return (
            <View style={{ color: shouldShowAlert ? 'red' : 'black' }} key={item.name}>
              <Text style={pdfStyles.text}>
                {parent && '- '}
                {item.name}: {value}
              </Text>
            </View>
          );
      }
    });
  };

  const renderField = (currentValues, oppositeValues, form, parent = false, outbound = false) => {
    return form?.map((item) => {
      const currentValue = currentValues?.[item.name];
      const oppositeValue = oppositeValues?.[item.name];

      let shouldShowAlert = bol?.inbound?.date && currentValues?.[item.name] !== oppositeValues?.[item.name];

      if (bol?.type === 'inbound_only') shouldShowAlert = false;

      if (bol?.type === 'inbound_only' && !currentValues?.[item?.name]?.length && outbound) return null;

      if (!item?.mandatory && item.type !== 'object' && !currentValues?.[item?.name]?.length) return null;

      if (!!currentValue && !item.visible) return null;

      if (!currentValue) return null;

      switch (item.type) {
        case 'images':
          let different = false;

          if (bol?.inbound?.date) {
            different = currentValue?.length !== oppositeValue?.length;
            currentValue.forEach((image, imageIdx) => {
              if (image?.uri !== oppositeValue?.[imageIdx]?.uri || image?.label !== oppositeValue?.[imageIdx]?.label) {
                different = true;
              }
            });
          }

          return (
            <div key={item.name}>
              <Typography
                key={item.name}
                className={[
                  classes.listText,
                  'mb-1',
                  parent ? classes.bulletItem : '',
                  different ? classes.red : '',
                ].join(' ')}
              >
                {item.name}:&nbsp;{currentValue?.length} Photo{currentValue?.length > 1 && 's'} Uploaded
              </Typography>
              {currentValue?.map((el: any, idx: number) => {
                const differentLabel = different && el?.label !== oppositeValue?.[idx]?.label;
                return (
                  <div key={idx} className="mb-2 mt-2">
                    <img
                      src={!el.uri.includes('https') ? `data:image/jpeg;base64,${el.uri}` : el.uri}
                      alt="image"
                      className={classes.bolImg}
                    />
                    <Typography
                      className={[classes.listText, differentLabel ? classes.red : ''].join(' ')}
                      style={{ fontSize: '16px' }}
                    >
                      {item.name}&nbsp;-&nbsp;{el?.label || `Photo ${idx + 1}`}
                    </Typography>
                  </div>
                );
              })}
            </div>
          );

        case 'object':
          return (
            <div key={item.name} className="mb-3">
              <Typography key={item.name} className={[classes.listText].join(' ')}>
                {item.name}:
              </Typography>
              {renderField(currentValue, oppositeValue, item.children, true, outbound)}
            </div>
          );

        default:
          return (
            <Typography
              key={item.name}
              className={[classes.listText, shouldShowAlert ? classes.red : '', parent ? classes.bulletItem : ''].join(
                ' ',
              )}
            >
              {item.name}:&nbsp;{currentValue}
            </Typography>
          );
      }
    });
  };

  const renderDifferences = (differences = []) => {
    if (differences?.length) {
      return (
        <div className={classes.issues}>
          <Typography className={classes.issuesHeading}>Issues:</Typography>

          {differences?.map((diff, idx) => (
            <Typography key={idx} className={[classes.listText, classes.red].join(' ')}>
              {diff}
            </Typography>
          ))}
        </div>
      );
    }

    return <div></div>;
  };

  return (
    bol && (
      <div style={{ width: 'fit-content' }}>
        <div style={{ textAlign: 'right' }} className="mx-2">
          {generatePDFDownloadLink()}
        </div>

        <div className={classes.boxesWrapper}>
          <Box className={[classes.boxWrapper, 'mx-2'].join(' ')}>
            <Box className={classes.box}>
              <Typography className={[classes.pdfHeader].join(' ')}>
                <strong>Record# {bol.code}</strong>
              </Typography>

              <Box className="mb-3">
                <Typography className="mb-2" style={{ fontSize: '20px', borderTop: '5px solid black' }}>
                  <strong>OUTBOUND</strong>
                </Typography>
                <Typography className={classes.listText}>{bol.outbound?.creator?.firstName}</Typography>
                <Typography className={classes.listText}>{outboundDate}</Typography>
                {renderField(bol?.outbound?.values, bol?.inbound?.values, user?.form, false, true)}
              </Box>
              <img src={barcode} className="w-100" />
            </Box>
          </Box>

          {bol?.inbound?.date && (
            <Box className={[classes.boxWrapper, 'mx-2'].join(' ')}>
              <Box className={classes.box}>
                <Typography className={[classes.pdfHeader].join(' ')}>
                  <strong>Record# {bol.code}</strong>
                </Typography>

                <Box className="mb-3">
                  <Typography className="mb-2" style={{ fontSize: '20px', borderTop: '5px solid black' }}>
                    <strong>INBOUND</strong>
                  </Typography>
                  <Typography className={classes.listText}>{bol.inbound?.creator?.firstName}</Typography>
                  <Typography className={classes.listText}>{inboundDate}</Typography>
                  {renderField(bol?.inbound?.values, bol?.outbound?.values, user?.form)}
                  {bol?.type === 'inbound_only' && renderDifferences(bol?.differences)}
                </Box>
                <img src={barcode} className="w-100" />
              </Box>
            </Box>
          )}
        </div>
      </div>
    )
  );
};

export default PageBolDetails;
