import { patch, post } from '../utils/axios';

export const createManager = async (fields: any) => {
  const body = {
    name: fields.name,
    email: fields.email,
    features: fields.features,
    locations: Object.keys(fields.locations || {}).filter((location) => fields.locations[location]),
  };

  await post(`/managers`, body);
};

export const updateManager = async (managerId: string, fields: any) => {
  const body = {
    name: fields.name,
    email: fields.email,
    features: fields.features,
    locations: Object.keys(fields.locations || {}).filter((location) => fields.locations[location]),
    disabled: fields.disabled,
  };

  await patch(`/managers/${managerId}`, body);
};
