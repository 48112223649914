import { toast } from 'react-toastify';
import { TOAST_CONFIG } from '../constants';

export const toastError = (error: any) => {
  let msg = 'Something went wrong';

  if (error.response.status === 403) msg = "You don't have access";

  if (error?.response?.data?.message) msg = error.response.data.message;

  toast.error(msg, TOAST_CONFIG);
};
