import { useQuery } from 'react-query';
import { QUERY_KEYS } from '../constants';
import { fetchCustomerUser } from '../api/customer';
import { CustomerProfile } from '../pages/customer/User';

export const useGetCustomerUser = (customerId: string, userId: string) => {
  const { data, error, isLoading, refetch } = useQuery<any, any, CustomerProfile, string[]>({
    queryKey: [QUERY_KEYS.customer_user_profile, customerId, userId],
    queryFn: () => fetchCustomerUser(customerId, userId),
    enabled: !!customerId && !!userId,
    placeholderData: {},
  });

  return {
    user: data,
    isLoading,
    error,
    refetch,
  };
};
