import React from 'react';
import { Button, Divider, makeStyles, MenuProps, TextField, Typography } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import { Col, Row } from 'reactstrap';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import SwapVertIcon from '@material-ui/icons/SwapVert';
import { ReorderHorizontal } from 'mdi-material-ui';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useMutation, useQuery } from 'react-query';
import FormComponent from '../../components/FormComponent';
import { FieldInfoContainer, FieldList, FieldRow, FieldType } from './PageBolFields.style';
import Loader from '../../components/Loader';
import { QUERY_KEYS, TOAST_CONFIG } from '../../constants';
import { getBolForm, saveOrEditForm } from '../../api/form';
import { toastError } from '../../utils/toastError';
import { dropDownOptions, dropDownOptionsForSubField, TYPE_MAP } from './constants';
import { queryClient } from '../../api/queryClient';

export const useStyles = makeStyles(() => ({
  fieldHeader: {
    fontWeight: 'bold',
  },
  fieldName: {
    fontSize: '12px',
    fontWeight: 'bold',
    position: 'absolute',
    top: '-22px',
  },
  dragHandle: {
    color: '#ccc',
  },
  centerCol: {
    display: 'flex',
    justifyContent: 'center',
  },
  addSubField: {
    color: '#5572EA',
    marginTop: '1rem',
    textTransform: 'initial',
  },
}));

const PageBolFields = () => {
  const classes = useStyles();

  const { handleSubmit, control, watch, setValue } = useForm();
  const { handleSubmit: handleSubmitSF, control: controlSF, watch: watchSF, setValue: setValueSF } = useForm();

  const watchFieldType = watch('fieldType');

  const { data, isLoading, error } = useQuery({
    queryKey: QUERY_KEYS.bol_form,
    queryFn: () => {
      return getBolForm();
    },
    onError: toastError,
  });

  const fields = data?.form || [];

  const saveOrEditMutation = useMutation((form: any[]) => saveOrEditForm(form, data?._id), {
    onError: toastError,
    onSuccess: (data) => {
      queryClient.setQueryData(QUERY_KEYS.bol_form, data);
    },
  });

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result.map((el: any, i) => ({
      ...el,
      order: i + 1,
    }));
  };

  const onDragEnd = async (result) => {
    // dropped outside the list
    if (!result.destination) return;

    const orderedItems = reorder(fields, result.source.index, result.destination.index);

    await saveOrEditMutation.mutate(orderedItems);
  };

  const onDragEndSF = async (fieldName, result) => {
    // dropped outside the list
    if (!result.destination) return;

    const children = fields.find((x) => x.name === fieldName)?.children;

    const orderedItems = fields.map((f) =>
      f.name === fieldName ? { ...f, children: reorder(children, result.source.index, result.destination.index) } : f,
    );

    await saveOrEditMutation.mutate(orderedItems);
  };

  const handleChangeCheckbox = async (name: string, property: 'mandatory' | 'visible') => {
    const newCols = fields.map((col) => {
      if (col.name !== name) return col;

      const update = { ...col, [property]: !col[property] };

      if (col.type === 'object' && col[property]) {
        update['children'] = col.children.map((child) => ({ ...child, visible: false, mandatory: false }));
      }

      if (property === 'mandatory' && !col['mandatory']) update['visible'] = true;
      else if (property === 'visible' && col['visible']) update['mandatory'] = false;

      return update;
    });

    await saveOrEditMutation.mutate(newCols);
  };

  const handleChangeCheckboxSF = async (parentName: string, name: string, property: 'mandatory' | 'visible') => {
    const newFields = [...fields];
    const field = newFields.find((parent) => parent.name === parentName);

    if (!field) return;

    field['children'] = field['children'].map((child) => {
      if (child.name !== name) return child;

      const update = { ...child, [property]: !child[property] };

      if (property === 'mandatory' && !child['mandatory']) update['visible'] = true;
      else if (property === 'visible' && child['visible']) update['mandatory'] = false;

      return update;
    });

    const anyChildrenVisible = field['children'].some((child) => child.visible);

    field['visible'] = anyChildrenVisible;

    await saveOrEditMutation.mutate(newFields);
  };

  const MenuProps: Partial<MenuProps> = {
    getContentAnchorEl: null,
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'left',
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'left',
    },
  };

  const handleCreate = async (field: any) => {
    if (fields?.find((x) => x?.name?.toLowerCase() === field.fieldName?.toLowerCase())) {
      toast.error('Field already exists', TOAST_CONFIG);

      return;
    }

    try {
      const newFields = [
        ...fields,
        {
          mandatory: true,
          visible: true,
          order: fields?.length + 1 || 1,
          name: field.fieldName,
          type: field.fieldType,
          ...(field.fieldType === 'object' || field.fieldType === 'object-optional'
            ? {
                children: [],
              }
            : {}),
          ...(field.fieldType === 'list' ? { values: field.fieldValues.split(',') } : {}),
        },
      ];

      await saveOrEditMutation.mutate(newFields);

      setValue('fieldName', '');
      setValue('fieldType', '');
      setValue('fieldValues', '');
    } catch (error) {
      toast.error(error?.response?.data?.message, TOAST_CONFIG);
    }
  };

  const handleCreateSF = async (field: any) => {
    const { parentName, fieldName, fieldType, fieldValues } = field.data;
    const parentField = fields?.find((x) => x.name === parentName);

    if (parentField?.children?.find((x) => x?.name?.toLowerCase() === fieldName?.toLowerCase())) {
      toast.error('Sub-Field already exists', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      return;
    }

    try {
      const newFields = [
        ...(fields?.filter((x) => x.name !== parentName) || []),
        {
          ...parentField,
          children: [
            ...(parentField.children || []),
            {
              mandatory: parentField.madatory || true,
              visible: parentField.visible || true,
              order: parentField.children?.length + 1 || 1,
              name: fieldName,
              type: fieldType,
              ...(fieldType === 'list' ? { values: fieldValues.split(',') } : {}),
            },
          ],
        },
      ];

      await saveOrEditMutation.mutate(newFields);

      setValue('fieldName', '');
      setValue('fieldType', '');
      setValue('fieldValues', '');
      setValueSF('data.parentName', null);
    } catch (error) {
      toast.error(error?.response?.data?.message, TOAST_CONFIG);
    }
  };

  const addSubFieldTo = (item) => {
    setValueSF('data.fieldName', '');
    setValueSF('data.fieldType', '');
    setValueSF('data.fieldValues', '');
    setValueSF('data.parentName', item.name);
  };

  const cancelAddSf = () => {
    setValueSF('data.fieldName', '');
    setValueSF('data.fieldType', '');
    setValueSF('data.fieldValues', '');
    setValueSF('data.parentName', null);
  };

  const watchSFAll = watchSF();

  return (
    <>
      <Row className="mb-5">
        <Col xl={6} lg={9} md={12}>
          <Typography variant="h5" className="mb-3">
            Manage TruckSync Input Fields
          </Typography>
          <Typography>
            TruckSync input fields represent the real-life data you want your team members to confirm for inbound and
            outbound inspections in the mobile app. After you add a field, you can reorder them, make them required, or
            hide them — this will affect how they appear and function in the mobile app. If you hide a field, it will no
            longer appear in all upcoming Records (but will not affect previous Records you created).
          </Typography>
        </Col>
      </Row>
      <Row className="mb-5">
        <Col xl={6} lg={9} md={12}>
          <Typography>
            <strong>Add a New Field</strong>
          </Typography>
          <Divider className="mt-1 mb-3" />
          <FormComponent
            onSubmit={handleSubmit(handleCreate)}
            error={error?.response?.data?.message}
            hasBorder={false}
            hasPadding={false}
          >
            <Controller
              name="fieldName"
              control={control}
              defaultValue=""
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <TextField
                  autoFocus
                  onChange={onChange}
                  error={!!error}
                  helperText={error ? error.message : null}
                  value={value}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="fieldName"
                  label="Enter New Field Name"
                  name="fieldName"
                  className="mb-3"
                />
              )}
              rules={{
                required: 'Field Name required',
              }}
            />
            <Controller
              name="fieldType"
              control={control}
              render={({ field: { onChange, value } }) => {
                return (
                  <FormControl variant="outlined" component="fieldset" margin="normal">
                    <InputLabel id="field-type-label">Choose Field Type</InputLabel>
                    <Select
                      labelId="field-type-label"
                      value={value}
                      onChange={onChange}
                      MenuProps={MenuProps}
                      label="Choose Field Type"
                    >
                      {dropDownOptions.map((item) => (
                        <MenuItem key={item.value} value={item.value}>
                          {item.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                );
              }}
              rules={{
                required: 'Field Type required',
              }}
            />

            {watchFieldType === 'list' && (
              <Controller
                name="fieldValues"
                control={control}
                defaultValue=""
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    autoFocus
                    onChange={onChange}
                    error={!!error}
                    helperText={error ? error.message : 'Ex: Apples,Oranges,Cherries'}
                    value={value}
                    variant="outlined"
                    fullWidth
                    id="fieldValues"
                    label="Dropdown Values"
                    name="fieldValues"
                  />
                )}
                rules={{
                  required: 'Dropdown Values required',
                }}
              />
            )}

            <Loader isLoading={saveOrEditMutation.isLoading} small className="mt-2">
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                disabled={saveOrEditMutation.isLoading}
              >
                ADD FIELD
              </Button>
            </Loader>
          </FormComponent>
        </Col>
      </Row>
      <Row>
        <Col>
          <Loader isLoading={isLoading} wide>
            <Row>
              <Col md={12} lg={9} xl={6}>
                <Row>
                  <div style={{ width: '50px' }}>
                    <SwapVertIcon />
                  </div>
                  <Col xs={4} sm={6}>
                    <Typography className={classes.fieldHeader}>Current Fields</Typography>
                  </Col>
                  <Col xs={4} sm={2} className={classes.centerCol}>
                    <Typography className={classes.fieldHeader}>Required</Typography>
                  </Col>
                  <Col xs={2} sm={2} className={classes.centerCol}>
                    <Typography className={classes.fieldHeader}>Show/Hide</Typography>
                  </Col>
                </Row>
                <Divider className="mt-1 mb-1" />
              </Col>
            </Row>

            <Row>
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable">
                  {(provided) => (
                    <FieldList {...provided.droppableProps} ref={provided.innerRef}>
                      {fields
                        .sort((a, b) => a.order - b.order)
                        .map((item, index) => (
                          <Draggable key={`${item.name}_${index}`} draggableId={`${item.name}_${index}`} index={index}>
                            {(provided) => (
                              <FieldRow ref={provided.innerRef} {...provided.draggableProps}>
                                <Row>
                                  <Col md={12} lg={9} xl={6}>
                                    <Row>
                                      <div style={{ width: '50px' }}>
                                        <div {...provided.dragHandleProps}>
                                          <ReorderHorizontal className={classes.dragHandle} />
                                        </div>
                                      </div>
                                      <Col xs={5} sm={6}>
                                        <FieldInfoContainer>
                                          <Typography className={classes.fieldName}>{item.name}</Typography>
                                          <FieldType>
                                            {TYPE_MAP[item.type]}&nbsp;
                                            {item.type === 'list' && (
                                              <>
                                                <span>[</span>
                                                {item.values?.map((value, i) => (
                                                  <span style={{ display: 'inline-block' }} key={i}>
                                                    {i !== 0 && ','}
                                                    {value}
                                                  </span>
                                                ))}
                                                <span>]</span>
                                              </>
                                            )}
                                          </FieldType>
                                        </FieldInfoContainer>
                                      </Col>
                                      <Col xs={2} className={classes.centerCol}>
                                        {item.type !== 'object' && (
                                          <Checkbox
                                            checked={item.mandatory}
                                            onChange={() => handleChangeCheckbox(item.name, 'mandatory')}
                                          />
                                        )}
                                      </Col>{' '}
                                      <Col xs={2} className={classes.centerCol}>
                                        <Checkbox
                                          checked={item.visible}
                                          onChange={() => handleChangeCheckbox(item.name, 'visible')}
                                        />
                                      </Col>
                                    </Row>
                                  </Col>
                                  {(item.type === 'object' || item.type === 'object-optional') && (
                                    <>
                                      <Col mr={12}></Col>
                                      <Col md={12} lg={9} xl={6}>
                                        <DragDropContext onDragEnd={(result) => onDragEndSF(item.name, result)}>
                                          <Droppable droppableId="droppable">
                                            {(provided) => (
                                              <FieldList {...provided.droppableProps} ref={provided.innerRef}>
                                                {item.children
                                                  ?.sort((a, b) => a.order - b.order)
                                                  .map((subItem, index) => (
                                                    <Draggable
                                                      key={subItem.name}
                                                      draggableId={subItem.name}
                                                      index={index}
                                                    >
                                                      {(provided) => (
                                                        <FieldRow ref={provided.innerRef} {...provided.draggableProps}>
                                                          <Row>
                                                            <div style={{ width: '50px', marginLeft: '8.4%' }}>
                                                              <div {...provided.dragHandleProps}>
                                                                <ReorderHorizontal className={classes.dragHandle} />
                                                              </div>
                                                            </div>
                                                            <Col xs={4} sm={5}>
                                                              <FieldInfoContainer>
                                                                <Typography className={classes.fieldName}>
                                                                  {subItem.name}
                                                                </Typography>
                                                                <FieldType>
                                                                  {TYPE_MAP[subItem.type]}&nbsp;
                                                                  {subItem.type === 'list' && (
                                                                    <>
                                                                      <span>[</span>
                                                                      {subItem.values?.map((value, i) => (
                                                                        <span
                                                                          style={{ display: 'inline-block' }}
                                                                          key={i}
                                                                        >
                                                                          {i !== 0 && ','}
                                                                          {value}
                                                                        </span>
                                                                      ))}
                                                                      <span>]</span>
                                                                    </>
                                                                  )}
                                                                </FieldType>
                                                              </FieldInfoContainer>
                                                            </Col>
                                                            <Col xs={2} className={classes.centerCol}>
                                                              <Checkbox
                                                                checked={subItem.mandatory}
                                                                onChange={() =>
                                                                  handleChangeCheckboxSF(
                                                                    item.name,
                                                                    subItem.name,
                                                                    'mandatory',
                                                                  )
                                                                }
                                                              />
                                                            </Col>{' '}
                                                            <Col xs={2} className={classes.centerCol}>
                                                              <Checkbox
                                                                checked={subItem.visible}
                                                                onChange={() =>
                                                                  handleChangeCheckboxSF(
                                                                    item.name,
                                                                    subItem.name,
                                                                    'visible',
                                                                  )
                                                                }
                                                              />
                                                            </Col>
                                                          </Row>
                                                        </FieldRow>
                                                      )}
                                                    </Draggable>
                                                  ))}
                                                {provided.placeholder}
                                              </FieldList>
                                            )}
                                          </Droppable>
                                        </DragDropContext>
                                        <Col xs={{ size: 10, offset: 2 }}>
                                          {watchSFAll.data?.parentName === item.name ? (
                                            <Col xs={10} className="mt-4 mx-2">
                                              <FormComponent
                                                onSubmit={handleSubmitSF(handleCreateSF)}
                                                hasBorder={false}
                                                hasPadding={false}
                                              >
                                                <Controller
                                                  name={`data.fieldName`}
                                                  control={controlSF}
                                                  defaultValue=""
                                                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                                                    <TextField
                                                      autoFocus
                                                      onChange={onChange}
                                                      error={!!error}
                                                      helperText={error ? error.message : null}
                                                      value={value}
                                                      variant="outlined"
                                                      margin="normal"
                                                      fullWidth
                                                      label="Enter New Field Name"
                                                    />
                                                  )}
                                                  rules={{
                                                    required: 'Field Name required',
                                                  }}
                                                />
                                                <Controller
                                                  name={`data.fieldType`}
                                                  control={controlSF}
                                                  render={({ field: { onChange, value } }) => {
                                                    return (
                                                      <FormControl
                                                        variant="outlined"
                                                        component="fieldset"
                                                        margin="normal"
                                                      >
                                                        <InputLabel id="subfield-type-label">
                                                          Choose Field Type
                                                        </InputLabel>
                                                        <Select
                                                          labelId="subfield-type-label"
                                                          value={value}
                                                          onChange={onChange}
                                                          MenuProps={MenuProps}
                                                          label="Choose Field Type"
                                                        >
                                                          {dropDownOptionsForSubField.map((item) => (
                                                            <MenuItem key={item.value} value={item.value}>
                                                              {item.label}
                                                            </MenuItem>
                                                          ))}
                                                        </Select>
                                                      </FormControl>
                                                    );
                                                  }}
                                                  rules={{
                                                    required: 'Field Type required',
                                                  }}
                                                />

                                                {watchSFAll.data.fieldType === 'list' && (
                                                  <Controller
                                                    name={`data.fieldValues`}
                                                    control={controlSF}
                                                    defaultValue=""
                                                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                                                      <TextField
                                                        autoFocus
                                                        onChange={onChange}
                                                        error={!!error}
                                                        helperText={
                                                          error ? error.message : 'Ex: Apples,Oranges,Cherries'
                                                        }
                                                        value={value}
                                                        variant="outlined"
                                                        fullWidth
                                                        label="Dropdown Values"
                                                      />
                                                    )}
                                                    rules={{
                                                      required: 'Dropdown Values required',
                                                    }}
                                                  />
                                                )}

                                                <Loader isLoading={saveOrEditMutation.isLoading} small className="mt-2">
                                                  <div style={{ display: 'flex' }}>
                                                    <Button
                                                      type="submit"
                                                      fullWidth
                                                      variant="contained"
                                                      color="primary"
                                                      disabled={saveOrEditMutation.isLoading}
                                                    >
                                                      ADD FIELD
                                                    </Button>
                                                    <Button
                                                      fullWidth
                                                      variant="contained"
                                                      disabled={saveOrEditMutation.isLoading}
                                                      style={{ marginLeft: '16px' }}
                                                      onClick={cancelAddSf}
                                                    >
                                                      CANCEL
                                                    </Button>
                                                  </div>
                                                </Loader>
                                              </FormComponent>
                                            </Col>
                                          ) : !watchSFAll.data?.parentName ? (
                                            <Button className={classes.addSubField} onClick={() => addSubFieldTo(item)}>
                                              + Add another sub-field
                                            </Button>
                                          ) : null}
                                        </Col>
                                      </Col>
                                    </>
                                  )}
                                </Row>
                              </FieldRow>
                            )}
                          </Draggable>
                        ))}
                      {provided.placeholder}
                    </FieldList>
                  )}
                </Droppable>
              </DragDropContext>
            </Row>
          </Loader>
        </Col>
      </Row>
    </>
  );
};

export default PageBolFields;
