import { Box, Checkbox, MenuItem, OutlinedInput, Select, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { Col } from 'reactstrap';
import { createUser, fetchAllCustomerLocations, fetchCustomerSummary, updateUser } from '../../api/customer';
import { queryClient } from '../../api/queryClient';
import FormComponent from '../../components/FormComponent';
import Loader from '../../components/Loader';
import { QUERY_KEYS, TOAST_CONFIG } from '../../constants';
import { toastError } from '../../utils/toastError';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Features } from '../../types';
import { KeyboardDatePicker } from '@material-ui/pickers';
import moment from 'moment';
import { BabacoTheme } from '../../styles/theme';
import { useGetCustomerUser } from '../../hooks/useGetCustomerUser';
import { useGetCustomerUsers } from '../../hooks/useGetCustomerUsers';

export type CustomerProfile = {
  firstName: string;
  lastName: string;
  company: string;
  address: string;
  email: string;
  phone: string;
  features: Features;
  type: 'owner' | 'admin' | 'manager';
  locations: string[];
  overageFee?: number;
  overageThreshold?: number;
  billingCycle?: Date;
};

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: 40,
  },
  form: {
    width: '100%',
  },
  label: {
    marginBottom: 10,
    fontSize: 12,
    fontWeight: 'bold',
    color: '#000000',
  },
  input: {
    marginBottom: 20,
  },
  checkboxes: {
    marginTop: 40,
    marginBottom: 40,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
  checkbox: {
    display: 'flex',
    alignItems: 'center',
  },
  empty: {
    height: 19,
    width: 19,
    border: '2px solid rgba(118, 118, 118)',
    cursor: 'pointer',
    marginLeft: 10,
    marginRight: 12,
    marginTop: -4,
  },
  picker: {
    marginTop: 20,
    marginBottom: 30,
  },
}));

const User = () => {
  const params: any = useParams();

  const classes = useStyles();

  const customerId = params.id;
  const userId = params.userId;

  const [showPassword, setShowPassword] = useState(false);

  const { data: summary } = useQuery({
    queryKey: [QUERY_KEYS.customer_summary, customerId],
    queryFn: () => fetchCustomerSummary(customerId),
    onError: toastError,
  });

  const { data: users } = useGetCustomerUsers(customerId);

  const admins = users?.pages?.[0]?.users?.filter((item) => item?.type === 'admin') || [];

  const { user, error, isLoading, refetch: refetchCustomer } = useGetCustomerUser(customerId, userId);

  const { data: locations } = useQuery({
    queryKey: [QUERY_KEYS.customer_locations_all, customerId],
    queryFn: () => fetchAllCustomerLocations(customerId),
    enabled: !!customerId,
    placeholderData: [],
  });

  const onSuccess = async () => {
    await queryClient.refetchQueries({ queryKey: [QUERY_KEYS.customer_profile, customerId] });
    await queryClient.refetchQueries({ queryKey: [QUERY_KEYS.customer_users, customerId] });
    await queryClient.refetchQueries({ queryKey: [QUERY_KEYS.customers] });
    reset();
  };

  const { mutate: create, isLoading: createIsLoading } = useMutation((fields: any) => createUser(fields, customerId), {
    onError: toastError,
    onSuccess: async () => {
      await onSuccess();
      toast.success('User created successfully', TOAST_CONFIG);
    },
  });

  const { mutate: update, isLoading: updateIsLoading } = useMutation(
    (fields: any) => updateUser(fields, customerId, userId),
    {
      onError: toastError,
      onSuccess: async () => {
        await refetchCustomer();
        await onSuccess();
        toast.success('User updated successfully', TOAST_CONFIG);
      },
    },
  );

  const { handleSubmit, control, reset, watch, setValue } = useForm({
    values: {
      firstName: user?.firstName || '',
      lastName: user?.lastName || '',
      email: user?.email || '',
      company: user?.company || summary?.name || '',
      address: user?.address || '',
      phoneNumber: user?.phone || '',
      password: '',
      type: user?.type || '',
      newOwner: '',
      features: user?.features || {
        bol: false,
        waire: false,
        logistic: false,
        inspection: false,
      },
      overageFee: user?.overageFee || 0,
      overageThreshold: user?.overageThreshold || 0,
      billingCycle: user?.billingCycle || undefined,
      locations: user?.locations
        ? locations.reduce((acc, curr) => {
            acc[curr._id] = !!user.locations.find((location) => location === curr._id);
            return acc;
          }, {})
        : locations.reduce((acc, curr) => {
            acc[curr._id] = true;
            return acc;
          }, {}),
    },
  });

  return (
    <div className={classes.container}>
      <Col md={{ size: 5, offset: 0 }}>
        <FormComponent
          className={classes.form}
          onSubmit={handleSubmit(userId ? (update as any) : (create as any))}
          error={error?.response?.data?.message || ''}
        >
          <Controller
            name="firstName"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <div className={classes.input}>
                <InputLabel htmlFor="firstName" className={classes.label}>
                  * First Name
                </InputLabel>
                <TextField
                  onChange={onChange}
                  error={!!error}
                  helperText={error ? error.message : null}
                  value={value}
                  variant="outlined"
                  fullWidth
                  name="firstName"
                  id="firstName"
                  required
                />
              </div>
            )}
            rules={{ required: 'First Name required' }}
          />

          <Controller
            name="lastName"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <div className={classes.input}>
                <InputLabel htmlFor="lastName" className={classes.label}>
                  * Last Name
                </InputLabel>
                <TextField
                  onChange={onChange}
                  error={!!error}
                  helperText={error ? error.message : null}
                  value={value}
                  variant="outlined"
                  required
                  fullWidth
                  name="lastName"
                  id="lastName"
                />
              </div>
            )}
            rules={{ required: 'Last Name required' }}
          />

          <Controller
            name="company"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <div className={classes.input}>
                <InputLabel htmlFor="company" className={classes.label}>
                  * Company
                </InputLabel>
                <TextField
                  onChange={onChange}
                  error={!!error}
                  helperText={error ? error.message : null}
                  value={value}
                  variant="outlined"
                  required
                  fullWidth
                  name="company"
                  id="company"
                  disabled={user?.type != 'owner'}
                />
              </div>
            )}
            rules={{
              required: 'Company required',
            }}
          />

          <Controller
            name="type"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <div className={classes.input}>
                <InputLabel htmlFor="type" className={classes.label}>
                  * Role
                </InputLabel>
                <Select
                  onChange={onChange}
                  error={!!error}
                  value={value}
                  fullWidth
                  id="type"
                  name="type"
                  required
                  placeholder="Choose Role From List"
                  variant="outlined"
                  MenuProps={{
                    getContentAnchorEl: null,
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'left',
                    },
                    transformOrigin: {
                      vertical: 'top',
                      horizontal: 'left',
                    },
                  }}
                >
                  {user?.type === 'owner' && <MenuItem value="owner">Owner</MenuItem>}
                  <MenuItem value="admin">Admin</MenuItem>
                  <MenuItem value="manager">Manager</MenuItem>
                </Select>
              </div>
            )}
            rules={{
              required: 'Role required',
            }}
          />

          {user?.type === 'owner' && watch('type') !== 'owner' && (
            <Controller
              name="newOwner"
              control={control}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <div className={classes.input}>
                  <InputLabel htmlFor="type" className={classes.label}>
                    * New Owner
                  </InputLabel>
                  <Select
                    onChange={onChange}
                    error={!!error}
                    value={value}
                    fullWidth
                    id="newOwner"
                    name="newOwner"
                    required
                    placeholder="Choose User"
                    variant="outlined"
                    MenuProps={{
                      getContentAnchorEl: null,
                      anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left',
                      },
                      transformOrigin: {
                        vertical: 'top',
                        horizontal: 'left',
                      },
                    }}
                  >
                    {admins.map((user) => (
                      <MenuItem value={user._id} key={user.email}>
                        {user.email}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
              )}
              rules={{
                required: 'Role required',
              }}
            />
          )}

          <Controller
            name="email"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <div className={classes.input}>
                <InputLabel htmlFor="email" className={classes.label}>
                  * Email
                </InputLabel>
                <TextField
                  onChange={onChange}
                  error={!!error}
                  helperText={error ? error.message : null}
                  value={value}
                  variant="outlined"
                  fullWidth
                  id="email"
                  name="email"
                  required
                />
              </div>
            )}
            rules={{
              required: 'Email required',
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                message: 'Enter a valid e-mail address',
              },
            }}
          />

          <Controller
            name="password"
            control={control}
            render={({ field: { onChange, value } }) => (
              <div className={classes.input}>
                <InputLabel htmlFor="password" className={classes.label}>
                  Password
                </InputLabel>
                <TextField
                  onChange={onChange}
                  value={value}
                  variant="outlined"
                  fullWidth
                  name="password"
                  id="password"
                  autoComplete="current-password"
                  type={showPassword ? 'text' : 'password'}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setShowPassword(!showPassword)}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
            )}
          />

          <Controller
            name="address"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <div className={classes.input}>
                <InputLabel htmlFor="address" className={classes.label}>
                  * Address
                </InputLabel>
                <TextField
                  onChange={onChange}
                  error={!!error}
                  helperText={error ? error.message : null}
                  value={value}
                  variant="outlined"
                  fullWidth
                  name="address"
                  id="address"
                  required
                />
              </div>
            )}
          />

          <Controller
            name="phoneNumber"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <div className={classes.input}>
                <InputLabel htmlFor="phoneNumber" className={classes.label}>
                  Phone Number
                </InputLabel>
                <TextField
                  type="tel"
                  onChange={onChange}
                  error={!!error}
                  helperText={error ? error.message : null}
                  value={value}
                  variant="outlined"
                  fullWidth
                  name="phoneNumber"
                  id="phoneNumber"
                />
              </div>
            )}
            rules={{
              pattern: {
                value: /^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/i,
                message: 'Enter a valid phone number. Example: 212-456-7890',
              },
            }}
          />

          <div className={classes.checkboxes}>
            {watch('type') !== 'manager' && (
              <Controller
                name="features.bol"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <div className={classes.checkbox}>
                    <Checkbox id="features.bol" name="features.bol" checked={value} onChange={onChange} />
                    <InputLabel htmlFor="features.bol" className={classes.label} style={{ marginTop: 10 }}>
                      TruckSync
                    </InputLabel>
                  </div>
                )}
              />
            )}

            {watch('type') !== 'manager' && (
              <Controller
                name="features.inspection"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <div className={classes.checkbox}>
                    <Checkbox id="features.inspection" name="features.inspection" checked={value} onChange={onChange} />
                    <InputLabel htmlFor="features.inspection" className={classes.label} style={{ marginTop: 10 }}>
                      Inspection
                    </InputLabel>
                  </div>
                )}
              />
            )}

            <Controller
              name="features.waire"
              control={control}
              render={({ field: { onChange, value } }) => (
                <div className={classes.checkbox}>
                  <Checkbox id="features.waire" name="features.waire" checked={value} onChange={onChange} />
                  <InputLabel htmlFor="features.waire" className={classes.label} style={{ marginTop: 10 }}>
                    WAIRE
                  </InputLabel>
                </div>
              )}
            />

            {watch('type') !== 'manager' && (
              <Controller
                name="features.logistic"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <div className={classes.checkbox}>
                    <Checkbox
                      id="features.logistic"
                      name="features.logistic"
                      checked={value}
                      onChange={onChange}
                      disabled={!summary?.features?.waire || !watch('features.waire')}
                    />
                    <InputLabel htmlFor="features.logistic" className={classes.label} style={{ marginTop: 10 }}>
                      3PL
                    </InputLabel>
                  </div>
                )}
              />
            )}
          </div>

          <div className={classes.checkboxes} style={{ flexDirection: 'column' }}>
            <Typography variant="h4" style={{ fontWeight: 'bold', fontSize: 14, marginBottom: 30 }}>
              Warehouse Permissions
            </Typography>

            {locations.map((location) => (
              <Controller
                key={location._id}
                name={`locations.${location._id}`}
                control={control}
                render={({ field: { value } }) => (
                  <div className={classes.checkbox}>
                    <Box
                      id={`locations.${location._id}`}
                      className={classes.empty}
                      style={{
                        backgroundColor: value ? BabacoTheme.colors.yellow : '#ffffff',
                        opacity: user?.type === 'owner' ? 0.6 : 1,
                        cursor: user?.type === 'owner' ? 'not-allowed' : 'pointer',
                      }}
                      onClick={() => {
                        if (user?.type === 'owner') return;
                        setValue(`locations.${location._id}`, !value);
                      }}
                    />
                    <InputLabel
                      htmlFor={`locations.${location._id}`}
                      style={{ marginTop: 10, marginBottom: 10, fontSize: 12, color: '#000000' }}
                    >
                      {location.name}
                    </InputLabel>
                  </div>
                )}
              />
            ))}
          </div>

          {user?.type === 'owner' && (
            <div>
              <Controller
                name="overageFee"
                control={control}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <div className={classes.input}>
                    <InputLabel htmlFor="overageFee" className={classes.label}>
                      Overage Fee
                    </InputLabel>
                    <OutlinedInput
                      onChange={onChange}
                      error={!!error}
                      value={value}
                      required
                      fullWidth
                      name="overageFee"
                      id="overageFee"
                      type="number"
                      startAdornment={<InputAdornment position="start">$</InputAdornment>}
                    />
                  </div>
                )}
                rules={{ required: 'Location Fee is required' }}
              />

              <Controller
                name="overageThreshold"
                control={control}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <div className={classes.input}>
                    <InputLabel htmlFor="overageThreshold" className={classes.label}>
                      Overage Threshold (Records)
                    </InputLabel>
                    <OutlinedInput
                      onChange={onChange}
                      error={!!error}
                      value={value}
                      required
                      fullWidth
                      name="overageThreshold"
                      id="overageThreshold"
                      type="number"
                    />
                  </div>
                )}
              />

              <Controller
                name="billingCycle"
                control={control}
                render={({ field: { onChange, value } }) => {
                  return (
                    <div className={classes.picker}>
                      <InputLabel htmlFor="billingCycle" className={classes.label}>
                        Billing Cycle Start Date
                      </InputLabel>
                      <KeyboardDatePicker
                        placeholder="Choose Date"
                        InputAdornmentProps={{ position: 'start' }}
                        style={!value ? { boxShadow: '0 3px 6px 0px #00000029' } : {}}
                        okLabel={<span style={{ color: '#000' }}>Ok</span>}
                        cancelLabel={<span style={{ color: '#000' }}>Cancel</span>}
                        inputVariant="outlined"
                        disableToolbar
                        id="billingCycle"
                        name="billingCycle"
                        format="MM/DD/yyyy"
                        views={['year', 'month', 'date']}
                        value={moment(value)}
                        disabled={!!user?.billingCycle}
                        onChange={onChange}
                      />
                    </div>
                  );
                }}
              />
            </div>
          )}

          <Loader isLoading={isLoading || createIsLoading || updateIsLoading} small>
            <Button type="submit" fullWidth variant="contained" color="primary" style={{ marginBottom: 20 }}>
              {userId ? 'UPDATE' : 'CREATE'}
            </Button>
          </Loader>
        </FormComponent>
      </Col>
    </div>
  );
};

export default User;
