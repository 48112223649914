import React, { useContext } from 'react';
import { Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useQuery } from 'react-query';
import { AuthContext } from '../../auth';
import Table from '../../components/Table';
import { formatDate } from '../../utils/helpers';
import { get } from '../../utils/axios';
import { QUERY_KEYS } from '../../constants';
import { toastError } from '../../utils/toastError';
import { Edit } from '@material-ui/icons';

const Managers = () => {
  const history = useHistory();

  const { user } = useContext(AuthContext);

  const { data, isLoading } = useQuery({
    queryKey: QUERY_KEYS.managers,
    queryFn: async () => {
      const { data } = await get('/managers');
      return data;
    },
    onError: toastError,
  });

  const columns = [
    {
      title: '',
      field: '',
      default: true,
      render: (data) => <Edit onClick={() => history.push(`/managers/${data._id}`)} style={{ cursor: 'pointer' }} />,
      minWidth: 30,
    },
    {
      title: 'User',
      field: 'firstName',
      default: true,
    },
    {
      title: 'Locations',
      field: 'locations',
      default: true,
      render: (data: any) => data?.locations?.length || '-',
    },
    {
      title: 'Email',
      field: 'email',
      default: true,
    },
    {
      title: 'Last Login',
      field: 'lastSignIn',
      default: true,
      render: (data: any) => (data.lastSignIn ? formatDate(data.lastSignIn, user.timezone) : '-'),
    },
    {
      title: 'Date Created',
      field: 'createdAt',
      default: true,
      render: (data: any) => (data.createdAt ? formatDate(data.createdAt, user.timezone) : '-'),
    },
  ];

  return (
    <Table
      pages={[data || []]}
      columns={columns}
      isLoading={isLoading}
      toolbarContent={[]}
      showToolbar={false}
      sorting={true}
      autoTableCell
      headerText={
        <Typography variant="h5" className="mb-5">
          Team Management / Location Managers
        </Typography>
      }
      headerRefresh={false}
    />
  );
};

export default Managers;
