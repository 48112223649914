import { toastError } from '../utils/toastError';
import { fetchCustomerUsers } from '../api/customer';
import { QUERY_KEYS } from '../constants';
import { useInfiniteQuery } from 'react-query';
import { Features, UserType } from '../types';

type CustomerUser = {
  _id: string;
  features: Features;
  type: UserType;
  firstName: string;
  lastName?: string;
  email: string;
  address: string;
  enabled: boolean;
  locations: number;
  bols: number;
  inspections: number;
  waires: number;
  createdAt: Date;
  signIn?: Date;
};

export const useGetCustomerUsers = (customerId: string) => {
  const { data, isLoading, fetchNextPage } = useInfiniteQuery<any, unknown, { users: CustomerUser[]; total: number }>({
    queryKey: [QUERY_KEYS.customer_users, customerId],
    queryFn: ({ pageParam = 1 }) => fetchCustomerUsers(pageParam, customerId),
    getNextPageParam: (_, pages) => {
      return pages.length + 1;
    },
    onError: toastError,
  });

  return {
    data,
    isLoading,
    fetchNextPage,
  };
};
