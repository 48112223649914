import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import moment from 'moment-timezone';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { Col, Container, Row } from 'reactstrap';
import FormComponent from '../../components/FormComponent';
import Loader from '../../components/Loader';
import { post } from '../../utils/axios';
import { Checkbox, InputLabel, MenuItem, OutlinedInput, Select } from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { toastError } from '../../utils/toastError';
import { QUERY_KEYS, TOAST_CONFIG } from '../../constants';
import { queryClient } from '../../api/queryClient';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  container: {
    // marginTop: theme.spacing(16),
  },
  form: {
    width: '100%', // Fix IE 11 issue.
  },
  forgotPassword: {
    marginTop: theme.spacing(2),
  },
  label: {
    marginBottom: 10,
    fontSize: 12,
    fontWeight: 'bold',
    color: '#000000',
  },
  input: {
    marginBottom: 20,
  },
  checkboxes: {
    marginTop: 20,
    marginBottom: 20,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
  checkbox: {
    display: 'flex',
    alignItems: 'center',
  },
  picker: {
    marginTop: 20,
    marginBottom: 30,
  },
}));

const Register = () => {
  const classes = useStyles();
  const { handleSubmit, control, watch, reset } = useForm();
  const history = useHistory();

  const [apiError, setApiError] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleRegister = async (fields) => {
    setIsSubmitting(true);
    setApiError(null);

    try {
      await post('/customers/register', {
        ...fields,
        timezone: moment.tz.guess(),
      });

      toast.success('User account created!', TOAST_CONFIG);
      reset();
      await queryClient.refetchQueries({ queryKey: [QUERY_KEYS.customers] });
      history.push('/customers');
    } catch (error) {
      toast.error(error?.response?.data?.message, TOAST_CONFIG);
    }

    setIsSubmitting(false);
  };

  return (
    <Container className={classes.container}>
      <Row>
        <Col md={{ size: 8, offset: 0 }}>
          <Row style={{ marginBottom: '60px' }}>
            <Typography variant="h4" style={{ fontWeight: 'bold', fontSize: '20px' }}>
              Register New Customer
            </Typography>
          </Row>
          <Row>
            <Col lg={{ size: 8, offset: 0 }}>
              <FormComponent className={classes.form} onSubmit={handleSubmit(handleRegister)} error={apiError?.message}>
                <Controller
                  name="firstName"
                  control={control}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <div className={classes.input}>
                      <InputLabel htmlFor="firstName" className={classes.label}>
                        * First Name
                      </InputLabel>
                      <TextField
                        onChange={onChange}
                        error={!!error}
                        helperText={error ? error.message : null}
                        value={value}
                        variant="outlined"
                        fullWidth
                        name="firstName"
                        id="firstName"
                        required
                      />
                    </div>
                  )}
                  rules={{ required: 'First Name required' }}
                />

                <Controller
                  name="lastName"
                  control={control}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <div className={classes.input}>
                      <InputLabel htmlFor="lastName" className={classes.label}>
                        * Last Name
                      </InputLabel>
                      <TextField
                        onChange={onChange}
                        error={!!error}
                        helperText={error ? error.message : null}
                        value={value}
                        variant="outlined"
                        fullWidth
                        name="lastName"
                        id="lastName"
                        required
                      />
                    </div>
                  )}
                  rules={{ required: 'Last Name required' }}
                />

                <Controller
                  name="company"
                  control={control}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <div className={classes.input}>
                      <InputLabel htmlFor="company" className={classes.label}>
                        * Company
                      </InputLabel>
                      <TextField
                        onChange={onChange}
                        error={!!error}
                        helperText={error ? error.message : null}
                        value={value}
                        variant="outlined"
                        fullWidth
                        name="company"
                        id="company"
                        required
                      />
                    </div>
                  )}
                  rules={{ required: 'Company name required' }}
                />

                <div className={classes.input}>
                  <InputLabel htmlFor="type" className={classes.label}>
                    * Role
                  </InputLabel>
                  <TextField value={'Owner'} variant="outlined" fullWidth name="type" id="type" disabled />
                </div>

                <Controller
                  name="email"
                  control={control}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <div className={classes.input}>
                      <InputLabel htmlFor="email" className={classes.label}>
                        * Email
                      </InputLabel>
                      <TextField
                        onChange={onChange}
                        error={!!error}
                        helperText={error ? error.message : null}
                        value={value}
                        variant="outlined"
                        fullWidth
                        name="email"
                        id="email"
                        required
                      />
                    </div>
                  )}
                  rules={{
                    required: 'Email required',
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                      message: 'Enter a valid e-mail address',
                    },
                  }}
                />

                <Controller
                  name="password"
                  control={control}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <div className={classes.input}>
                      <InputLabel htmlFor="password" className={classes.label}>
                        * Password
                      </InputLabel>
                      <TextField
                        onChange={onChange}
                        error={!!error}
                        helperText={error ? error.message : null}
                        value={value}
                        variant="outlined"
                        fullWidth
                        name="password"
                        id="password"
                        required
                        type={showPassword ? 'text' : 'password'}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => setShowPassword(!showPassword)}
                                edge="end"
                              >
                                {showPassword ? <VisibilityOff /> : <Visibility />}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </div>
                  )}
                  rules={{ required: 'Password required' }}
                />

                <Controller
                  name="address"
                  control={control}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <div className={classes.input}>
                      <InputLabel htmlFor="address" className={classes.label}>
                        Address
                      </InputLabel>
                      <TextField
                        onChange={onChange}
                        error={!!error}
                        helperText={error ? error.message : null}
                        value={value}
                        variant="outlined"
                        fullWidth
                        name="address"
                        id="address"
                      />
                    </div>
                  )}
                />

                <Controller
                  name="phone"
                  control={control}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <div className={classes.input}>
                      <InputLabel htmlFor="phone" className={classes.label}>
                        Phone Number
                      </InputLabel>
                      <TextField
                        onChange={onChange}
                        error={!!error}
                        helperText={error ? error.message : null}
                        value={value}
                        variant="outlined"
                        fullWidth
                        name="phone"
                        id="phone"
                      />
                    </div>
                  )}
                  rules={{
                    required: 'Phone number required',
                    pattern: {
                      value: /^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/i,
                      message: 'Enter a valid phone number. Example: 212-456-7890',
                    },
                  }}
                />

                <div>
                  <Controller
                    name="overageFee"
                    control={control}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                      <div className={classes.input}>
                        <InputLabel htmlFor="overageFee" className={classes.label}>
                          Overage Fee
                        </InputLabel>
                        <OutlinedInput
                          onChange={onChange}
                          error={!!error}
                          value={value}
                          required
                          fullWidth
                          name="overageFee"
                          id="overageFee"
                          startAdornment={<InputAdornment position="start">$</InputAdornment>}
                        />
                      </div>
                    )}
                    rules={{ required: 'Location Fee is required' }}
                  />

                  <Controller
                    name="overageThreshold"
                    control={control}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                      <div className={classes.input}>
                        <InputLabel htmlFor="overageThreshold" className={classes.label}>
                          Overage Threshold (Records)
                        </InputLabel>
                        <OutlinedInput
                          onChange={onChange}
                          error={!!error}
                          value={value}
                          required
                          fullWidth
                          name="overageThreshold"
                          id="overageThreshold"
                          type="number"
                        />
                      </div>
                    )}
                  />

                  <div className={classes.checkboxes}>
                    <Controller
                      name="features.bol"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <div className={classes.checkbox}>
                          <Checkbox id="features.bol" name="features.bol" checked={value} onChange={onChange} />
                          <InputLabel htmlFor="features.bol" className={classes.label} style={{ marginTop: 10 }}>
                            TruckSync
                          </InputLabel>
                        </div>
                      )}
                    />

                    <Controller
                      name="features.inspection"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <div className={classes.checkbox}>
                          <Checkbox
                            id="features.inspection"
                            name="features.inspection"
                            checked={value}
                            onChange={onChange}
                          />
                          <InputLabel htmlFor="features.inspection" className={classes.label} style={{ marginTop: 10 }}>
                            Inspection
                          </InputLabel>
                        </div>
                      )}
                    />

                    <Controller
                      name="features.waire"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <div className={classes.checkbox}>
                          <Checkbox id="features.waire" name="features.waire" checked={value} onChange={onChange} />
                          <InputLabel htmlFor="features.waire" className={classes.label} style={{ marginTop: 10 }}>
                            WAIRE
                          </InputLabel>
                        </div>
                      )}
                    />

                    <Controller
                      name="features.logistic"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <div className={classes.checkbox}>
                          <Checkbox
                            id="features.logistic"
                            name="features.logistic"
                            checked={value}
                            onChange={onChange}
                            disabled={!watch('features.waire')}
                          />
                          <InputLabel htmlFor="features.logistic" className={classes.label} style={{ marginTop: 10 }}>
                            3PL
                          </InputLabel>
                        </div>
                      )}
                    />
                  </div>

                  <Controller
                    name="billingCycle"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <div className={classes.picker}>
                        <InputLabel htmlFor="billingCycle" className={classes.label}>
                          Billing Cycle Start Date
                        </InputLabel>
                        <KeyboardDatePicker
                          placeholder="Choose Date"
                          InputAdornmentProps={{ position: 'start' }}
                          style={{ boxShadow: '0 3px 6px 0px #00000029' }}
                          okLabel={<span style={{ color: '#000' }}>Ok</span>}
                          cancelLabel={<span style={{ color: '#000' }}>Cancel</span>}
                          inputVariant="outlined"
                          disableToolbar
                          id="billingCycle"
                          name="billingCycle"
                          format="MM/DD/yyyy"
                          views={['year', 'month', 'date']}
                          value={value}
                          onChange={onChange}
                        />
                      </div>
                    )}
                  />
                </div>

                <Loader isLoading={isSubmitting} small>
                  <Button type="submit" fullWidth variant="contained" color="primary" className="mt-2">
                    CREATE ACCOUNT
                  </Button>
                </Loader>
              </FormComponent>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default Register;
