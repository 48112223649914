import React, { useCallback, useContext, useState } from 'react';
import IconButton from '@material-ui/core/IconButton';
import Checkbox from '@material-ui/core/Checkbox';
import { Typography, makeStyles } from '@material-ui/core';
import { Link, useHistory } from 'react-router-dom';
import { MapMarkerPlus } from 'mdi-material-ui';
import { toast } from 'react-toastify';
import { useQuery } from 'react-query';
import { AuthContext } from '../../auth';
import Table from '../../components/Table';
import { formatDate } from '../../utils/helpers';
import { get, patch } from '../../utils/axios';
import { QUERY_KEYS, TOAST_CONFIG } from '../../constants';
import { queryClient } from '../../api/queryClient';
import { toastError } from '../../utils/toastError';

const bolColumns = [
  {
    title: 'Open Records',
    field: 'open',
    default: true,
    sorting: true,
  },
  {
    title: 'Closed Records',
    field: 'closed',
    default: true,
    sorting: true,
  },
  {
    title: 'Total Records',
    field: 'total',
    default: true,
    sorting: true,
  },
];

const inspectionColumns = [
  {
    title: 'Inspections',
    field: 'inspections',
    default: true,
    sorting: true,
  },
];

const waireColumns = [
  {
    title: 'Records',
    field: 'waires',
    default: true,
    sorting: true,
  },
  {
    title: 'Trips',
    field: 'trips',
    default: true,
    sorting: true,
  },
  {
    title: 'Visits',
    field: 'visits',
    default: true,
    sorting: true,
  },
];

const logisticColumns = [
  {
    title: 'Customers',
    field: 'customers',
    default: true,
    sorting: true,
  },
];

const useStyles = makeStyles(() => ({
  accountSettingsDropdown: {
    marginRight: 'auto',
  },
}));

const PageLocationList = () => {
  const classes = useStyles();
  const history = useHistory();

  const { user } = useContext(AuthContext);

  const logistic = user?.features?.logistic;
  const waire = user?.features?.waire;
  const bol = user?.features?.bol;
  const isWaireOnly = waire && !bol && !logistic;

  const [sort, setSort] = useState<{ field: string; order: 'asc' | 'desc' | '' }>({ field: '', order: '' });

  const sortData = useCallback(
    (data) => {
      const locations = [...data.locations];

      if (sort.field === 'location') {
        if (sort.order === 'asc') locations.sort((a, b) => b[sort.field]?.localeCompare(a[sort.field] || ''));
        else if (sort.order === 'desc') locations.sort((a, b) => a[sort.field]?.localeCompare(b[sort.field] || ''));
      } else {
        if (sort.order === 'asc') locations.sort((a, b) => b[sort.field] - a[sort.field]);
        else if (sort.order === 'desc') locations.sort((a, b) => a[sort.field] - b[sort.field]);
      }

      return { ...data, locations };
    },
    [sort.field, sort.order],
  );

  const { data, isLoading } = useQuery({
    queryKey: QUERY_KEYS.locations,
    queryFn: async () => {
      const { data } = await get('/locations');
      return data;
    },
    select: (data) => sortData(data),
    onError: toastError,
  });

  const handleRefresh = async () => {
    setSort({ field: '', order: '' });
    queryClient.resetQueries({ queryKey: QUERY_KEYS.locations });
  };

  const updateLocation = (locationID, features) => {
    return async (e) => {
      e.stopPropagation();

      try {
        await patch(`/locations/${locationID}`, features);
        queryClient.resetQueries({ queryKey: [QUERY_KEYS.locations] });
      } catch (error) {
        toast.error(error?.response?.data?.message, TOAST_CONFIG);
      }
    };
  };

  const bolCheckbox = {
    title: 'TruckSync',
    field: 'bol',
    default: true,
    // @ts-ignore
    render: (data) => (
      <Checkbox
        disabled={data.location.includes('3rd Party')}
        checked={data?.features?.bol}
        onClick={updateLocation(data._id, { bol: !data?.features?.bol })}
      />
    ),
  };

  const inspectionCheckbox = {
    title: 'Inspection',
    field: 'inspection',
    default: true,
    // @ts-ignore
    render: (data) => (
      <Checkbox
        disabled={data.location.includes('3rd Party')}
        checked={data?.features?.inspection}
        onClick={updateLocation(data._id, { inspection: !data?.features?.inspection })}
      />
    ),
  };

  const waireCheckbox = {
    title: 'WAIRE',
    field: 'isWAIRE',
    default: true,
    // @ts-ignore
    render: (data) => (
      <Checkbox
        disabled={data.location.includes('3rd Party')}
        checked={data?.features?.waire}
        onClick={updateLocation(data._id, { waire: !data?.features?.waire })}
      />
    ),
  };

  const logisticCheckbox = {
    title: '3PL',
    field: 'logistic',
    default: true,
    // @ts-ignore
    render: (data) => (
      <Checkbox
        disabled={!data?.features?.waire || data.location.includes('3rd Party')}
        checked={data?.features?.logistic}
        onClick={updateLocation(data._id, {
          logistic: !data?.features?.logistic,
        })}
      />
    ),
  };

  const columns = [
    ...(user?.features?.bol ? [bolCheckbox] : []),
    ...(user?.features?.inspection ? [inspectionCheckbox] : []),
    ...(user?.features?.waire ? [waireCheckbox] : []),
    ...(user?.features?.logistic ? [logisticCheckbox] : []),
    {
      title: 'Location',
      field: 'location',
      default: true,
      sorting: true,
    },
    ...(user?.features?.bol ? bolColumns : []),
    ...(user?.features?.inspection ? inspectionColumns : []),
    ...(user?.features?.waire ? waireColumns : []),
    ...(user?.features?.logistic ? logisticColumns : []),
    {
      title: 'Created',
      field: 'created',
      default: true,
      render: (data: any) => (data.created ? formatDate(data.created, user.timezone) : '-'),
    },
    {
      title: 'Signed In',
      field: 'lastSignIn',
      default: true,
      render: (data: any) => (data.lastSignIn ? formatDate(data.lastSignIn, user.timezone) : '-'),
    },
    {
      title: 'Email',
      field: 'email',
      default: true,
      render: (data: any) => data.email || '-',
    },
    {
      title: 'Address',
      field: 'address',
      default: true,
      render: (data: any) => data.address || '-',
    },
  ];

  const onRowClick = (_, rowData) => {
    if (isWaireOnly) return;
    let url = `/bol-list/${rowData._id}?location=${rowData?.location}`;
    if (logistic) url = `waire-location/${rowData._id}`;
    history.push(url);
  };

  const toolbarContent = [
    {
      isExpandable: false,
      title: '',
      icon: '',
      content: (
        <div key={0} className={classes.accountSettingsDropdown}>
          <Link to="/location-add">
            <IconButton color="primary">
              <MapMarkerPlus />
              &nbsp;Add New location
            </IconButton>
          </Link>
        </div>
      ),
    },
  ];

  return (
    <Table
      pages={[data?.locations || []]}
      columns={columns}
      onRowClick={onRowClick}
      toolbarContent={toolbarContent}
      showToolbar={!logistic}
      isLoading={isLoading}
      sorting={true}
      handleSortChange={(field: string, order: 'asc' | 'desc') => setSort({ field, order })}
      type="location"
      headerText="View Locations"
      headerRefresh={bol}
      headerHits={bol ? data?.total || 0 : -1}
      onRefresh={handleRefresh}
    />
  );
};

export default PageLocationList;
