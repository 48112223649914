export const USER_TYPES = {
  ADMIN: 'admin',
  USER: 'user',
};

export enum UserType {
  SUPERADMIN = 'superadmin',
  ADMIN = 'admin',
  LOCATION = 'location',
  MANAGER = 'manager',
  OWNER = 'owner',
}

export type Features = {
  bol: boolean;
  waire: boolean;
  logistic: boolean;
};

export type Units = {
  locations: number;
  bols: number;
  waires: number;
  inspections: number;
};

export type Customer = {
  _id: string;
  features: Features;
  units: Units;
  name: string;
  email: string;
  createdAt: Date;
  signIn?: Date;
};

export type WAIRE = {
  _id: string;
  code: string;
  vin: string;
  bodyClass: string;
  fuelTypePrimary: string;
  fuelTypeSecondary: string;
  gvwr: string;
  modelYear: number;
  driveType: string;
  creator: string;
  type: string;
  createdAt: Date;
};

type ZeroEmission = {
  class8: number;
  'class4-7': number;
  'class2E-3': number;
};

type NearZeroEmission = Omit<ZeroEmission, 'class2E-3'>;

export type WAIRESummary = {
  trips: {
    class8: number;
    'class2E-7': number;
  };
  visits: {
    batteryElectric: ZeroEmission;
    hydrogenFuelCell: ZeroEmission;
    naturalGas: NearZeroEmission;
    lng: NearZeroEmission;
  };
};
