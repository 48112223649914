import React, { useContext, useEffect, useState } from 'react';
import { Button, FormControlLabel, makeStyles, TextField, Typography } from '@material-ui/core';
import { AccountCog, ChartBox, AccountOff } from 'mdi-material-ui';
import { Controller, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useInfiniteQuery, useMutation, useQuery } from 'react-query';
import { AuthContext } from '../../auth';
import CustomSwitch from '../../components/CustomSwitch';
import FormComponent from '../../components/FormComponent';
import Table from '../../components/Table';
import TableFilterBolWithoutLocation from '../../components/TableFilterBolWithoutLocation';
import { formatDate } from '../../utils/helpers';
import { patch } from '../../utils/axios';
import { getDynamicFields } from '../../utils/getDynamicFields';
import { fetchBols, fetchLocation } from '../../api/bol';
import { bolListKeys } from '../../utils/queryKeys';
import { PAGE_LIMIT, QUERY_KEYS, TOAST_CONFIG } from '../../constants';
import { queryClient } from '../../api/queryClient';
import { toastError } from '../../utils/toastError';

const useStyles = makeStyles(() => ({
  colAlert: {
    display: 'block',
    color: '#FF0000',
    whiteSpace: 'nowrap',
  },
  form: {
    width: '300px',
  },
}));

const PageBolListWithLocation = (props) => {
  const classes = useStyles();
  const history = useHistory();

  const locationId = props.match.params?.id;
  const search = props?.location?.search;
  const params = new URLSearchParams(search);
  const locationName = params.get('location');

  const { handleSubmit, control } = useForm();

  const { user } = useContext(AuthContext);

  const [filters, setFilters] = useState<any>({});

  const { data: location } = useQuery(QUERY_KEYS.bols_location_summary, () => {
    return fetchLocation(locationId);
  });

  const { data, isLoading, fetchNextPage } = useInfiniteQuery({
    queryKey: [QUERY_KEYS.bols_from_location, locationId, ...bolListKeys(filters)],
    queryFn: ({ pageParam = 1 }) => fetchBols(pageParam, filters, locationId),
    getNextPageParam: (_, pages) => {
      return pages.length + 1;
    },
    onError: toastError,
  });

  const locationEditMutation = useMutation(
    ({ email, address, password }: { email: string; address: string; password: string }) =>
      patch(`/user/location/${locationId}`, { email, address, password }),
    {
      onSuccess: () => {
        toast.success('Location successfully updated', TOAST_CONFIG);
      },
      onError: toastError,
    },
  );

  const userDisableMutation = useMutation(() => patch(`/user/disable/${locationId}`, {}), {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: QUERY_KEYS.bols_location_summary });
      toast.success('Location successfully updated', TOAST_CONFIG);
    },
    onError: toastError,
  });

  const hasMoreData = data?.pages?.length * PAGE_LIMIT < user?.total;

  const handleRefresh = async () => {
    setFilters({});
    queryClient.resetQueries({ queryKey: QUERY_KEYS.bols_from_location });
  };

  useEffect(() => {
    return () => {
      localStorage.removeItem('location-field-values');
    };
  }, []);

  const columns = [
    {
      title: 'Status',
      field: 'status',
      mandatory: true,
      default: true,
    },
    { title: 'TruckSync #', field: 'code', mandatory: true, default: true },
    { title: 'Outbound', field: 'outbound', default: true, render: (data) => data?.outbound?.creator?.firstName },
    {
      title: 'Date Opened',
      field: 'outboundDate',
      default: true,
      render: (data) =>
        data?.outbound?.date ? formatDate(data?.outbound?.date, data?.outbound?.creator?.timezone) : '-',
    },
    ...getDynamicFields(user?.form),
    { title: 'Inbound', field: 'inbound', default: true, render: (data) => data?.inbound?.creator?.firstName },
    {
      title: 'Date Closed',
      field: 'inboundDate',
      default: true,
      render: (data) => (data?.inbound?.date ? formatDate(data?.inbound?.date, data?.inbound?.creator?.timezone) : '-'),
    },
    {
      title: 'Late',
      field: 'late',
      default: true,
      render: (data) => (data?.late ? <div className={classes.colAlert}>Yes</div> : 'No'),
    },
    {
      title: 'Alerts',
      field: 'differences',
      render: (data) =>
        data?.differences?.length
          ? data?.differences?.map((diff) => (
              <div className={classes.colAlert} key={diff}>
                {diff}
              </div>
            ))
          : '-',
    },
  ];

  const onRowClick = (_, rowData) => {
    history.push(`/bol/${rowData.code}`);
  };

  const toolbarContent = [
    {
      isExpandable: true,
      title: 'Location Summary',
      icon: <ChartBox />,
      content: (
        <>
          <Typography className="">{location?.name}</Typography>
          {location?.email && <Typography className="text-nowrap">{location?.email}</Typography>}
          {location?.email && (
            <Typography className="text-nowrap">
              Created: {formatDate(location?.createdAt, location.timezone)}
            </Typography>
          )}
          {location?.email && location?.signIn && (
            <Typography className="text-nowrap">
              Signed in: {formatDate(location?.signIn, location.timezone)}
            </Typography>
          )}
          <Typography className="text-nowrap">Total Records: {location?.total}</Typography>
          {location?.email && (
            <>
              <Typography className="text-nowrap">Open Records: {location?.open}</Typography>
              <Typography className="text-nowrap">Closed Records: {location?.closed}</Typography>
            </>
          )}
        </>
      ),
    },
  ];

  if (location?.email) {
    toolbarContent.push({
      isExpandable: true,
      title: 'Location Account Settings',
      icon: <AccountCog />,
      content: (
        <FormComponent
          className={classes.form}
          onSubmit={handleSubmit(locationEditMutation.mutate as any)}
          hasBorder={false}
          hasPadding={false}
        >
          <Controller
            name="address"
            control={control}
            defaultValue={location?.address || ''}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                onChange={onChange}
                error={!!error}
                helperText={error ? error.message : null}
                value={value}
                variant="outlined"
                margin="normal"
                fullWidth
                id="address"
                label="Address"
                name="address"
                autoComplete="address"
              />
            )}
            rules={{
              required: 'Address required',
            }}
          />
          <Controller
            name="email"
            control={control}
            defaultValue={location?.email || ''}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                onChange={onChange}
                error={!!error}
                helperText={error ? error.message : null}
                value={value}
                variant="outlined"
                margin="normal"
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
              />
            )}
            rules={{
              required: 'Email required',
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                message: 'Enter a valid e-mail address',
              },
            }}
          />
          <Controller
            name="password"
            control={control}
            defaultValue=""
            render={({ field: { onChange, value }, fieldState: { error } }) => {
              return (
                <TextField
                  onChange={onChange}
                  error={!!error}
                  helperText={error ? error.message : null}
                  value={value}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  name="password"
                  label="Password"
                  id="password"
                  type="password"
                />
              );
            }}
          />

          <Button type="submit" fullWidth variant="contained" color="primary" className="my-2">
            SAVE
          </Button>

          <FormControlLabel
            control={
              <CustomSwitch onChange={userDisableMutation.mutate as any} checked={location?.disabled || false} />
            }
            label={
              <span>
                <AccountOff /> <span style={{ fontSize: '14px' }}>Temporarily Disable Location</span>
              </span>
            }
          />
        </FormComponent>
      ),
    });
  }

  return (
    <Table
      pages={data?.pages || []}
      columns={columns}
      onRowClick={onRowClick}
      toolbarContent={toolbarContent}
      showToolbar={true}
      isLoading={isLoading}
      filterComponent={TableFilterBolWithoutLocation}
      hasMoreData={hasMoreData}
      autoTableCell
      headerText={location?.name || locationName}
      headerRefresh
      headerHits={location?.total || 0}
      filters={filters}
      setFilters={setFilters}
      fetchNextPage={fetchNextPage}
      onRefresh={handleRefresh}
    />
  );
};

export default PageBolListWithLocation;
