import moment from 'moment';
import { PAGE_LIMIT } from '../constants';
import { get } from '../utils/axios';
import { visits } from '../utils/constants';

export const fetchWaires = async (page = 1, filters: any = {}, locations: any) => {
  const applied = [];

  let outbound = '';
  let inbound = '';

  let gvwr: any;
  let fuelType: any;

  if (filters?.['outbound-location']) {
    outbound = filters?.['outbound-location'];
  }

  if (filters?.['inbound-location']) {
    inbound = filters?.['inbound-location'];
  }

  if (filters?.visits) {
    gvwr = filters?.visits?.split('/')[0]?.trim();
    fuelType = filters?.visits?.split('/')[1]?.trim();
  }

  const params: any = {
    page,
    limit: PAGE_LIMIT,
    startDate: filters?.startDate && moment(filters.startDate).format('YYYY-MM-DD'),
    endDate: filters?.endDate && moment(filters.endDate).format('YYYY-MM-DD'),
    trips: filters?.trips,
    outbound,
    inbound,
    gvwr,
    fuelType,
    logistic: filters?.logistic,
  };

  let url = '/waires?';

  if (params?.startDate && params?.endDate)
    applied.push(`${moment(filters.startDate).format('YYYY-MM-DD')} - ${moment(filters.endDate).format('YYYY-MM-DD')}`);
  if (params?.outbound) {
    const found = locations.find((loc) => loc._id === outbound)?.firstName;
    applied.push(found);
  }
  if (params?.inbound) {
    const found = locations.find((loc) => loc._id === inbound)?.firstName;
    applied.push(found);
  }
  if (params?.trips) applied.push(filters?.trips);
  if (filters?.visits) {
    const normalizedVisits = Object.keys(visits).reduce((acc, key) => {
      acc = [...acc, ...visits[key]];
      return acc;
    }, []);

    const found = normalizedVisits.find((item) => item.value === filters.visits)?.label;
    if (found) {
      applied.push(found.split('/')[0]?.trim());
      applied.push(found.split('/')[1]?.trim());
    }
  }

  for (const param in params) {
    if (params[param]) url += `${param}=${params[param]}&`;
  }

  const { data } = await get(url);
  return data;
};

export const generateReport = async (filters: any = {}) => {
  const params: any = {};

  // trips
  if (filters?.startDate) params['startDate'] = moment(filters.startDate).format('YYYY-MM-DD');
  if (filters?.endDate) params['endDate'] = moment(filters.endDate).format('YYYY-MM-DD');

  if (filters?.['inbound-location']) {
    params['location'] = filters['inbound-location'];
    params['type'] = 'inbound';
  }

  if (filters?.['outbound-location']) {
    params['location'] = filters['outbound-location'];
    params['type'] = 'outbound';
  }

  if (
    filters?.['inbound-location'] &&
    filters?.['outbound-location'] &&
    filters?.['inbound-location'] === filters?.['outbound-location']
  ) {
    params['location'] = filters['outbound-location'];
    delete params['type'];
  }

  if (filters?.trips) params['trip'] = filters.trips === 'All' ? filters.trips.toLowerCase() : filters.trips;

  if (filters?.visits) {
    if (filters.visits === 'All') {
      params['gvwr'] = 'all';
    } else {
      params['fuelType'] = filters.visits.split('/')[1].trim();
      params['gvwr'] = filters.visits.split('/')[0].trim();
    }
  }

  // compliance
  if (filters?.location && filters?.location !== 'All') params['location'] = filters.location;
  if (filters?.logistic && filters?.logistic !== 'All') params['logistic'] = filters.logistic;
  if (filters?.year) params['year'] = filters.year;

  const { data } = await get('/waires/generate-report', params);
  return data;
};
