import React, { useContext } from 'react';
import { useInfiniteQuery } from 'react-query';
import { useHistory, useParams } from 'react-router-dom';
import { AuthContext } from '../../auth';
import { PAGE_LIMIT, QUERY_KEYS, TOAST_CONFIG } from '../../constants';
import { toastError } from '../../utils/toastError';
import Table from '../../components/Table';
import { formatDate } from '../../utils/helpers';
import { Features, UserType } from '../../types';
import { fetchCustomerUsers } from '../../api/customer';
import { queryClient } from '../../api/queryClient';
import { patch } from '../../utils/axios';
import { toast } from 'react-toastify';
import { Checkbox } from '@material-ui/core';
import Edit from '@material-ui/icons/Edit';
import { useGetCustomerUsers } from '../../hooks/useGetCustomerUsers';

const Users = () => {
  const params: any = useParams();
  const history = useHistory();

  const customerId = params.id;

  const { user } = useContext(AuthContext);

  const { data, isLoading, fetchNextPage } = useGetCustomerUsers(customerId);

  const total = data?.pages?.[0]?.total || 0;
  const hasMoreData = data?.pages?.length * PAGE_LIMIT < total;
  const users = data?.pages?.map((item) => item.users);

  const updateUser = (userId, updates) => {
    return async (e) => {
      e.stopPropagation();

      try {
        await patch(`/customers/${customerId}/users/${userId}`, updates);
        await queryClient.refetchQueries({ queryKey: [QUERY_KEYS.customers] });
        await queryClient.refetchQueries({ queryKey: [QUERY_KEYS.customer_users, customerId] });
        await queryClient.refetchQueries({ queryKey: [QUERY_KEYS.customer_profile, customerId] });
      } catch (error) {
        toast.error(error?.response?.data?.message, TOAST_CONFIG);
      }
    };
  };

  const columns = [
    {
      title: '',
      field: '',
      default: true,
      render: (data) => (
        <Edit
          onClick={() => history.push(`/customers/${customerId}/users/${data._id}?tab=6`)}
          style={{ cursor: 'pointer' }}
        />
      ),
      minWidth: 30,
    },
    {
      title: 'Enable/Disable',
      field: 'disabled',
      default: true,
      render: (data) => (
        <Checkbox checked={!data.disabled} onClick={updateUser(data._id, { disabled: !data.disabled })} />
      ),
    },
    {
      title: 'TruckSync',
      field: 'bol',
      default: true,
      render: (data) => (
        <div style={{ marginLeft: -10 }}>
          <Checkbox
            checked={data.features.bol}
            onClick={updateUser(data._id, { features: { bol: !data.features.bol } })}
          />
        </div>
      ),
    },
    {
      title: 'CI',
      field: 'ci',
      default: true,
      render: (data) => (
        <div style={{ marginLeft: -10 }}>
          <Checkbox
            checked={data.features.inspection}
            onClick={updateUser(data._id, { features: { inspection: !data.features.inspection } })}
          />
        </div>
      ),
    },
    {
      title: 'WAIRE',
      field: 'waire',
      default: true,
      render: (data) => (
        <Checkbox
          checked={data.features.waire}
          onClick={updateUser(data._id, { features: { waire: !data.features.waire } })}
        />
      ),
    },
    {
      title: '3PL',
      field: 'logistic',
      default: true,
      render: (data) => (
        <Checkbox
          disabled={!data.features.waire}
          checked={data.features.logistic}
          onClick={updateUser(data._id, {
            features: { logistic: !data.features.logistic },
          })}
        />
      ),
    },
    { title: 'Name', field: 'name', default: true, render: (data) => `${data.firstName} ${data?.lastName || ''}` },
    {
      title: 'Role',
      field: 'type',
      default: true,
      render: (data) => data.type.slice(0, 1).toUpperCase() + data.type.slice(1),
    },
    { title: 'Email', field: 'email', default: true, minWidth: '300px' },
    { title: 'Locations', field: 'locations', default: true },
    { title: 'TruckSync Records', field: 'bols', default: true },
    { title: 'CIs', field: 'inspections', default: true },
    { title: 'WAIRE', field: 'waires', default: true },
    {
      title: 'Date Created',
      field: 'createdAt',
      default: true,
      render: (data) => formatDate(data?.createdAt, user?.timezone),
    },
    {
      title: 'Last Signed In',
      field: 'signIn',
      default: true,
      render: (data) => (data?.signIn ? formatDate(data?.signIn, user?.timezone) : '-'),
    },
  ];

  return (
    <Table
      pages={users || []}
      columns={columns}
      toolbarContent={[]}
      showToolbar={false}
      isLoading={isLoading}
      hasMoreData={hasMoreData}
      autoTableCell
      sorting={false}
      headerRefresh={false}
      fetchNextPage={fetchNextPage}
    />
  );
};

export default Users;
