import { get, patch, post } from '../utils/axios';
import { PAGE_LIMIT } from '../constants';
import moment from 'moment';

export const fetchCustomers = async (page = 1) => {
  const url = `/customers?page=${page}&limit=${PAGE_LIMIT}`;

  const { data } = await get(url);
  return data;
};

export const fetchCustomerLocations = async (page = 1, customerId: string) => {
  const { data } = await get(`/customers/${customerId}/locations?page=${page}&limit=${PAGE_LIMIT}`);
  return data;
};

export const fetchCustomerUsers = async (page = 1, customerId: string) => {
  const { data } = await get(`/customers/${customerId}/users?page=${page}&limit=${PAGE_LIMIT}`);
  return data;
};

export const fetchAllCustomerLocations = async (customerId: string) => {
  const { data } = await get(`/customers/${customerId}/locations/all`);
  return data;
};

export const fetchCustomerSummary = async (customerId: string) => {
  const { data } = await get(`/customers/${customerId}/summary`);
  return data;
};

export const fetchCustomerUser = async (customerId: string, userId: string) => {
  const { data } = await get(`/customers/${customerId}/users/${userId}`);
  return data;
};

export const createUser = async (fields: any, customerId: string) => {
  const body = {
    address: fields.address,
    email: fields.email,
    firstName: fields.firstName,
    lastName: fields.lastName,
    type: fields.type,
    phone: fields.phoneNumber,
    password: fields.password,
    features: fields.features,
    locations: Object.keys(fields.locations || {}).filter((location) => fields.locations[location]),
  };

  await post(`/customers/${customerId}/user`, body);
};

export const updateUser = async (fields: any, customerId: string, userId: string) => {
  const toUpdate = {
    address: fields.address,
    email: fields.email,
    company: fields.company,
    firstName: fields.firstName,
    lastName: fields.lastName,
    type: fields.type,
    phone: fields.phoneNumber,
    features: fields.features,
    locationFee: fields.locationFee,
    overageFee: fields.overageFee,
    overageThreshold: fields.overageThreshold,
    locations: Object.keys(fields.locations || {}).filter((location) => fields.locations[location]),
  };

  if (fields.password) toUpdate['password'] = fields.password;
  if (fields.newOwner) toUpdate['newOwner'] = fields.newOwner;

  await patch(`/customers/${customerId}/users/${userId}`, toUpdate);
};
