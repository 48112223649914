import React, { useContext, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import { useHistory } from 'react-router-dom';
import { ChartBox, HelpCircleOutline, DownloadOutline, FilterVariant } from 'mdi-material-ui';
import { useInfiniteQuery, useMutation, useQuery } from 'react-query';
import moment from 'moment';
import { toast } from 'react-toastify';
import ContainerInspectionFilter from '../../components/ContainerInspectionFilter';
import { formatDate } from '../../utils/helpers';
import Table from '../../components/Table';
import { AuthContext } from '../../auth';
import { PAGE_LIMIT, QUERY_KEYS, TOAST_CONFIG } from '../../constants';
import { fetchInspections, fetchInspectionsSummary, generateReport } from '../../api/inspection';
import { inspectionListKeys } from '../../utils/queryKeys';
import { queryClient } from '../../api/queryClient';
import { toastError } from '../../utils/toastError';
import { get, patch } from '../../utils/axios';
import { fetchUserDetails } from '../../api/user';

type Inspections = {
  inspections: any[];
  total: number;
};

const useStyles = makeStyles((theme) => ({
  dialogContainer: {
    '& .MuiDialog-paper': {
      maxWidth: 480,
      padding: '80px 40px 20px 40px',
      height: 520,
    },
  },
  alert: {
    display: 'block',
    color: '#FF0000',
    whiteSpace: 'nowrap',
  },
  filters: {
    display: 'flex',
    wordWrap: 'break-word',
    width: '100%',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    [theme.breakpoints.down('md')]: {
      marginTop: 10,
    },
  },
  download: {
    color: '#5572EA',
    cursor: 'pointer',
  },
  btn: {
    color: '#5572EA',
    background: 'transparent',
    border: 'none',
    marginRight: 8,
  },
  heading: {
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: 22,
  },
  desc: {
    fontSize: 16,
    maxWidth: '75%',
    margin: '20px auto',
    lineHeight: 1.1,
  },
  filter: {
    width: '60%',
    margin: '0 auto',
    boxShadow: '0 3px 6px 0px #00000029',
  },
}));

const InspectionList = () => {
  const classes = useStyles();
  const history = useHistory();

  const { user, setUser } = useContext(AuthContext);

  const params = new URLSearchParams(history?.location?.search);
  const inspector = params.get('inspector');

  const [filters, setFilters] = useState<any>({});
  const [open, setOpen] = useState(false);
  const [activeToolbarTab, setActiveToolbarTab] = useState(null);

  const { data: summary } = useQuery(QUERY_KEYS.inspections_summary, () => {
    return fetchInspectionsSummary(inspector);
  });

  const { data, isLoading, fetchNextPage } = useInfiniteQuery<any, unknown, Inspections>({
    queryKey: [QUERY_KEYS.inspections, ...inspectionListKeys(filters), inspector],
    queryFn: ({ pageParam = 1 }) => fetchInspections(pageParam, filters, inspector),
    getNextPageParam: (_, pages) => {
      return pages.length + 1;
    },
    onError: toastError,
  });

  const inspections = data?.pages?.map((item) => item.inspections);
  const total = data?.pages?.[0]?.total || 0;

  const hasMoreData = data?.pages?.length * PAGE_LIMIT < total;

  const { data: locations } = useQuery({
    queryKey: QUERY_KEYS.all_locations,
    queryFn: async () => {
      const { data } = await get('/locations/all');
      return data;
    },
    placeholderData: [],
  });

  const { data: inspectors } = useQuery({
    queryKey: QUERY_KEYS.container_inspections_filter_inspectors,
    queryFn: async () => {
      const { data } = await get('/inspectors');
      return data;
    },
    placeholderData: [],
  });

  const { mutate, isLoading: generateReportLoading } = useMutation(() => generateReport(filters), {
    onError: toastError,
    onSuccess: () => {
      toast.success('Report is being generated, it will be sent to your account email in few minutes.', TOAST_CONFIG);
    },
  });

  const handleRefresh = async () => {
    setFilters({});
    setActiveToolbarTab(null);
    localStorage.removeItem('inspection-field-values');
    queryClient.resetQueries({ queryKey: [QUERY_KEYS.inspections] });
  };

  useEffect(() => {
    return () => {
      localStorage.removeItem('inspection-field-values');
    };
  }, []);

  const onGenerateReport = () => {
    const datesExist = filters.endDate && filters.startDate;
    if (!datesExist) {
      toast.error("Please select date range in 'Filter and Search' to generate report.", TOAST_CONFIG);
      return;
    }

    if (!data?.pages?.[0]) {
      toast.error('No records exist for this timeframe.', TOAST_CONFIG);
      return;
    }

    const diff = moment(filters.endDate).diff(moment(filters.startDate), 'days');
    if (diff > 31) {
      toast.error(
        `The timeframe for generating a report should be less than 31 days. Your current timeframe was ${diff} days.`,
        TOAST_CONFIG,
      );
      return;
    }

    if (diff < 0) {
      toast.error(`The selected timeframe is invalid. End Date cannot be less than Start Date.`, TOAST_CONFIG);
      return;
    }

    mutate();
  };

  const { mutate: saveColumnMutation } = useMutation(
    ({ inspections }: { inspections: { columns: string[] } }) => patch(`/settings`, { inspections }),
    {
      onSuccess: async () => {
        const data = await fetchUserDetails();
        setUser(data);
      },
    },
  );

  const saveColumnOrder = (columns: any[]) => {
    const activeColumns = columns
      .filter((column: { active: boolean; title: string }) => column.active)
      .map((column) => column.title);

    saveColumnMutation({ inspections: { columns: activeColumns } });
  };

  const isDefault = (title: string) => !!user?.columns?.inspections.find((item) => item === title);

  const columns = [
    {
      title: 'Container #',
      field: 'code',
      default: isDefault('Container #'),
      minWidth: 160,
    },
    { title: 'Location', field: 'location', default: isDefault('Location') },
    {
      title: 'Date Created',
      field: 'createdAt',
      default: isDefault('Date Created'),
      render: (data) => formatDate(data?.createdAt, user?.timezone),
    },
    { title: 'Inspector', field: 'inspector', default: isDefault('Inspector') },
    ...user?.tags?.map((el: any) => ({
      title: `Tag - ${el.name}`,
      field: el.name,
      default: isDefault(`Tag - ${el.name}`),
      render: (row: any) => {
        return row?.['tags']?.[el.name] || '-';
      },
    })),
    {
      title: 'Issues',
      field: 'issues',
      default: isDefault('Issues'),
      render: (data) =>
        data?.issues?.length ? (
          <div className={classes.alert}>
            {data.issues.map((issue) => (
              <div key={issue} style={{ display: 'block' }}>
                {issue}
              </div>
            ))}
          </div>
        ) : (
          '-'
        ),
    },
    {
      title: 'Photos',
      field: 'photos',
      render: (data) => (data?.photos ? <div className={classes.alert}>{data.photos}</div> : '-'),
      default: isDefault('Photos'),
    },
    {
      title: 'Agriculture Inspection',
      field: 'agricultureInspection',
      render: (data) => (data?.extraInspection?.agriculture ? 'Yes' : 'No'),
      default: isDefault('Agriculture Inspection'),
    },
    {
      title: 'Hardware Inspection',
      field: 'hardwareInspection',
      render: (data) => (data?.extraInspection?.hardware ? 'Yes' : 'No'),
      default: isDefault('Hardware Inspection'),
    },
  ];

  const onRowClick = (_, rowData) => {
    setActiveToolbarTab(null);
    history.push(`/container-inspections/${rowData.code}`);
  };

  const toolbarContent = [
    {
      isExpandable: true,
      title: 'Summary',
      icon: <ChartBox />,
      content: (
        <>
          <Typography className="text-nowrap">Total Inspections: {summary?.inspections || 0}</Typography>
          <Typography className="text-nowrap">
            Inspections with Issues: {summary?.inspectionsWithIssues || 0}
          </Typography>
          <Typography className="text-nowrap">
            Inspections with No Issues: {summary?.inspectionsWithoutIssues || 0}
          </Typography>
          <Typography className="text-nowrap">Locations: {summary?.locations || 0}</Typography>
          <Typography className="text-nowrap">Inspectors: {summary?.inspectors || 0}</Typography>
        </>
      ),
    },
  ];

  const appliedTags = Object.keys(filters?.tags || {})
    .filter((tag) => filters?.tags[tag])
    .map((tag) => (
      <span key={tag} style={{ marginLeft: 2 }}>
        <b>Tag {tag}: </b>
        {filters.tags[tag]}
      </span>
    ));

  const header = (
    <div className={classes.filters}>
      <span>
        <span>
          <b>Total: </b>
          <span>{total}</span>
        </span>
        {filters.startDate && filters.endDate && (
          <span>
            <b> Timeframe: </b>
            <span>
              {moment(filters.startDate).format('ddd MMM DD, YYYY')} -{' '}
              {moment(filters.endDate).format('ddd MMM DD, YYYY')}
            </span>
          </span>
        )}
        <span>
          <b> Locations: </b>
          <span>{locations?.find((loc) => loc._id === filters.location)?.firstName || 'All'}</span>
        </span>
        <span>
          <b> Inspectors: </b>
          <span>{inspectors?.find((ins) => ins._id === filters.inspector)?.name || 'All'}</span>
        </span>
        {filters.issues && (
          <span>
            <b> Issues: </b>
            <span>{filters.issues == '1' ? 'No' : 'Yes'}</span>
          </span>
        )}
        {Object.keys(filters?.tags || {})?.length > 0 ? (
          appliedTags
        ) : (
          <span style={{ marginLeft: 5 }}>
            <b>Tags:</b> All
          </span>
        )}
      </span>

      <span className={classes.download}>
        <button className={classes.btn} onClick={onGenerateReport}>
          <DownloadOutline fontSize="medium" style={{ marginRight: '4px' }} />
          <span>{generateReportLoading ? 'Loading...' : 'Download PDF'}</span>
        </button>

        <HelpCircleOutline
          fontSize="medium"
          onClick={() => {
            setOpen(true);
            setActiveToolbarTab('');
          }}
        />
      </span>
    </div>
  );

  return (
    <>
      <Table
        pages={inspections || []}
        columns={columns}
        onRowClick={onRowClick}
        toolbarContent={toolbarContent}
        showToolbar={true}
        isLoading={isLoading}
        filterComponent={ContainerInspectionFilter}
        hasMoreData={hasMoreData}
        autoTableCell
        headerText="Container Inspections"
        headerRefresh
        headerHits={total}
        filters={filters}
        setFilters={setFilters}
        fetchNextPage={fetchNextPage}
        onRefresh={handleRefresh}
        header={header}
        activeTab={activeToolbarTab}
        setActiveTab={setActiveToolbarTab}
        saveColumnOrder={saveColumnOrder}
      />

      <Dialog open={open} className={classes.dialogContainer} onClose={() => setOpen(false)}>
        <Typography className={classes.heading}>DOWNLOAD REQUIREMENTS</Typography>
        <Typography className={classes.desc}>
          <ol>
            <li>You must choose Filter and Search first before you can download your Container Inspections PDF.</li>
            <li>You must choose a timeframe. </li>
            <li>You can only download up to one month at a time (up to 31 days).</li>
          </ol>
          <div style={{ margin: '20px 0px 20px 0px' }}>Your PDF:</div>
          <ul>
            <li>Will include one page for each Container Inspection.</li>
            <li>Will be sent as a link to your email.</li>
            <li>You can leave this page while the PDF is processing.</li>
          </ul>
        </Typography>

        <IconButton
          color="primary"
          className={classes.filter}
          onClick={() => {
            setOpen(false);
            setActiveToolbarTab('Filter and Search');
          }}
        >
          <FilterVariant style={{ marginRight: '8px' }} />
          Filter and Search
        </IconButton>
      </Dialog>
    </>
  );
};

export default InspectionList;
