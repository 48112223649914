import React from 'react';
import {
  MapMarkerPlus,
  FileDocumentMultiple,
  FileEdit,
  OfficeBuildingMarker,
  HelpCircle,
  Cog,
  Timelapse,
  FileDocument,
  ShieldAccount,
  EmailMultiple,
  AccountMultiple,
  Leaf,
  AccountCog,
} from 'mdi-material-ui';
import Login from '../pages/login';
import Register from '../pages/register';
import PageBolList from '../pages/pageBolList';
import PageLocationList from '../pages/pageLocationList';
import PageBolFields from '../pages/pageBolFields';
import PageLocationAdd from '../pages/pageLocationAdd';
import PageAccount from '../pages/pageAccount';
import PageSupport from '../pages/pageSupport';
import PageBolDetails from '../pages/pageBolDetails';
import PageBolListWithLocation from '../pages/pageBolListWithLocation';
import ResetPassword from '../pages/resetPassword';
import PageAlertSettings from '../pages/pageAlertSettings';
import PageEmailSettings from '../pages/pageEmailSettings';
import ContainerInspections from '../pages/containerInspections';
import Inspectors from '../pages/inspectors';
import Inspection from '../pages/containerInspection';
import Customers from '../pages/customers';
import Customer from '../pages/customer';
import Waires from '../pages/waires';
import Waire from '../pages/waire';
import Tags from '../pages/containerInspectionTags';
import CreateLogisticCustomer from '../pages/CreateLogisticCustomer';
import ManageLogisticCustomers from '../pages/ManageLogisticCustomers';
import ManageLogisticCustomer from '../pages/ManageLogisticCustomer';
import ManageLogisticLocation from '../pages/ManageLogisticLocation';
import { svgs } from './svgs';
import Manager from '../pages/Manager';
import Managers from '../pages/Managers';

export const adminRoutes = [
  {
    title: 'Login',
    url: '/login',
    component: Login,
    private: false,
  },
  {
    title: 'Reset password',
    url: '/reset-password',
    component: ResetPassword,
    private: false,
  },
  {
    title: 'Babaco TruckSync',
    key: 'bols',
    hasSubMenu: true,
    private: true,
    showOnMenu: true,
    icon: FileDocument,
    enableOnFeature: 'bol',
    routes: [
      {
        title: 'View Records',
        url: '/bol-list',
        icon: FileDocumentMultiple,
        component: PageBolList,
        private: true,
        showOnMenu: true,
      },
      {
        title: 'Input Fields',
        url: '/bol-fields',
        icon: FileEdit,
        component: PageBolFields,
        private: true,
        showOnMenu: true,
      },
      {
        title: 'Time-Based Alert Settings',
        url: '/alert-settings',
        icon: Timelapse,
        component: PageAlertSettings,
        private: true,
        showOnMenu: true,
      },
    ],
  },
  {
    title: 'Container Inspections',
    key: 'containers',
    hasSubMenu: true,
    private: true,
    showOnMenu: true,
    svg: svgs.containerInspections,
    enableOnFeature: 'inspection',
    routes: [
      {
        title: 'View Inspections',
        url: '/container-inspections',
        svg: svgs.viewInspections,
        component: ContainerInspections,
        private: true,
        showOnMenu: true,
      },
      {
        title: 'Manage Inspectors',
        url: '/inspectors',
        svg: svgs.viewInspectors,
        component: Inspectors,
        private: true,
        showOnMenu: true,
      },
      {
        title: 'Manage Tags',
        url: '/tags/manage',
        svg: svgs.tags,
        component: Tags,
        private: true,
        showOnMenu: true,
      },
    ],
  },
  {
    title: 'WAIRE Compliance',
    key: 'waire',
    hasSubMenu: true,
    private: true,
    showOnMenu: true,
    icon: Leaf,
    enableOnFeature: 'waire',
    routes: [
      {
        title: 'Trips Data',
        url: '/waire',
        svg: svgs.tripsData,
        component: Waires,
        private: true,
        showOnMenu: true,
        enableOnFeature: 'waire',
      },
      {
        title: 'Manage 3PL Customers',
        url: '/logistics',
        svg: svgs.manageLogistics,
        component: ManageLogisticCustomers,
        private: true,
        showOnMenu: true,
        enableOnFeature: 'showLogistics',
      },
      {
        title: 'Create 3PL Customer',
        url: '/logistics/create',
        svg: svgs.createLogisticCustomer,
        component: CreateLogisticCustomer,
        private: true,
        showOnMenu: true,
        enableOnFeature: 'showLogistics',
      },
      {
        title: 'View Logistic Customer',
        url: '/logistics/:id',
        component: ManageLogisticCustomer,
        private: true,
        enableOnFeature: 'showLogistics',
      },
    ],
  },
  {
    title: 'WAIRE Details',
    url: '/waire/:id',
    component: Waire,
    private: true,
    enableOnFeature: 'waire',
  },
  {
    title: 'WAIRE Location',
    url: '/waire-location/:id',
    component: ManageLogisticLocation,
    private: true,
    showOnMenu: false,
    enableOnFeature: 'logistic',
  },
  {
    title: 'Team Management',
    key: 'management',
    hasSubMenu: true,
    private: true,
    showOnMenu: true,
    icon: AccountCog,
    enableOnFeature: 'admin',
    routes: [
      {
        title: 'Location Managers',
        url: '/managers',
        svg: svgs.manageLogistics,
        component: Managers,
        private: true,
        showOnMenu: true,
      },
      {
        title: 'Create New Location Manager',
        url: '/managers/create',
        component: Manager,
        svg: svgs.createLogisticCustomer,
        private: true,
        showOnMenu: true,
      },
      {
        title: 'Update Location Manager',
        url: '/managers/:id',
        component: Manager,
        svg: svgs.createLogisticCustomer,
        private: true,
        showOnMenu: false,
      },
    ],
  },
  {
    title: 'View Locations',
    url: '/location-list',
    icon: OfficeBuildingMarker,
    component: PageLocationList,
    private: true,
    showOnMenu: true,
    enableOnFeature: 'admin',
  },
  {
    title: 'Add New Locations',
    url: '/location-add',
    icon: MapMarkerPlus,
    component: PageLocationAdd,
    private: true,
    showOnMenu: true,
    enableOnFeature: 'admin',
  },
  {
    title: 'View Inspection',
    url: '/container-inspections/:id',
    component: Inspection,
    private: true,
    enableOnFeature: 'bol',
  },
  {
    title: 'View TruckSync Location',
    url: '/bol-list/:id',
    component: PageBolListWithLocation,
    private: true,
    showOnMenu: false,
  },
  {
    title: 'Bol Details',
    url: '/bol/:id',
    component: PageBolDetails,
    private: true,
    enableOnFeature: 'bol',
  },
  {
    title: 'Email Settings',
    url: '/email-settings',
    icon: EmailMultiple,
    component: PageEmailSettings,
    private: true,
    showOnMenu: true,
    enableOnFeature: 'admin',
  },
  {
    title: 'My Account',
    url: '/account',
    icon: ShieldAccount,
    component: PageAccount,
    private: true,
    showOnMenu: true,
    enableOnFeature: 'admin',
  },
  {
    title: 'Support',
    url: '/support',
    icon: HelpCircle,
    component: PageSupport,
    private: true,
    showOnMenu: true,
    enableOnFeature: 'admin',
  },
];

export const superAdminRoutes = [
  {
    title: 'Login',
    url: '/login',
    component: Login,
    private: false,
  },
  {
    title: 'Register New Customer',
    url: '/register',
    component: Register,
    icon: Cog,
    private: true,
    showOnMenu: true,
  },
  {
    title: 'Customers',
    url: '/customers',
    component: Customers,
    icon: AccountMultiple,
    private: true,
    showOnMenu: true,
  },
  {
    title: 'View Customer',
    url: '/customers/:id',
    component: Customer,
    private: true,
    showOnMenu: false,
  },
  {
    title: 'View Customer',
    url: '/customers/:id/users/:userId',
    component: Customer,
    private: true,
    showOnMenu: false,
  },
  {
    title: 'Reset password',
    url: '/reset-password',
    component: ResetPassword,
    private: false,
  },
  {
    title: 'My Account',
    url: '/account',
    icon: ShieldAccount,
    component: PageAccount,
    private: true,
    showOnMenu: true,
  },
];
