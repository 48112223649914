import { Button, Checkbox } from '@material-ui/core';
import { Table } from 'antd';
import { DownloadOutline } from 'mdi-material-ui';
import React, { useContext, useState } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import * as XLSX from 'xlsx';
import { fetchBillingByInvoice } from '../../api/billing';
import { AuthContext } from '../../auth';
import { QUERY_KEYS, TOAST_CONFIG } from '../../constants';
import { formatDate } from '../../utils/helpers';
import { toastError } from '../../utils/toastError';
import moment from 'moment';
import { toast } from 'react-toastify';

const header = [
  'Locations',
  'TruckSync Records',
  'CIs',
  'WAIRE',
  'Transactions',
  'Overage',
  'Transaction Amount',
  'Overage Amount',
  'Sub Total',
];

const BillingByInvoice = () => {
  const params: any = useParams();
  const { user } = useContext(AuthContext);
  const [selectedRows, setSelectedRows] = useState([]);

  const { data } = useQuery({
    queryKey: [QUERY_KEYS.customer_billing_history_by_invoice, params.id],
    queryFn: () => fetchBillingByInvoice(params.id),
    onError: toastError,
  });

  const expandedRowRender = (data: any[]) => {
    const columns = [
      { key: '1', render: () => '', width: 140 },
      {
        key: 'locationName',
        render: (item) => <span>{item.locationName}</span>,
        width: 240,
      },
      { title: 'TruckSync Records', key: 'bols', render: (item) => item.bols, width: 100 },
      { title: 'CIs', key: 'inspections', render: (item) => item.inspections, width: 100 },
      { title: 'WAIRE', key: 'waires', render: (item) => item.waires, width: 100 },
      {
        title: 'Transactions',
        key: 'transactions',
        render: (item) => item.bols + item.inspections + item.waires,
        width: 100,
      },
      { title: 'Overage', key: 'overage', render: (item) => item.overage, width: 100 },
      {
        title: 'Transaction Fee',
        key: 'transaction-fee',
        render: (item) => `$${item.transactionFee}`,
        width: 100,
      },
      {
        title: 'Overage Fee',
        key: 'overage-fee',
        render: (item) => `$${item.overageFee}`,
        width: 100,
      },
      {
        title: 'Total',
        key: 'total',
        render: (item) => `$${item.transactionFee + item.overageFee}`,
        width: 100,
      },
    ];

    return (
      <Table
        columns={columns}
        dataSource={data}
        pagination={false}
        showHeader={false}
        bordered={false}
        rowKey={(item) => item.location}
        size="middle"
      />
    );
  };

  const columns = [
    {
      title: () => (
        <Checkbox
          checked={data?.map((item) => item._id)?.length === selectedRows?.length}
          onChange={(event) => {
            const all = data.map((item) => item._id);
            setSelectedRows(event.target.checked ? all : []);
          }}
        />
      ),
      render: (item) => {
        return (
          <Checkbox
            checked={!!selectedRows.find((row) => row === item._id)}
            onChange={() => {
              const exists = selectedRows.find((row) => row === item._id);
              const updated = exists ? selectedRows.filter((row) => row !== item._id) : [...selectedRows, item._id];
              setSelectedRows(updated);
            }}
          />
        );
      },
      width: 50,
    },
    Table.EXPAND_COLUMN,
    {
      title: 'Invoice',
      key: 'invoice-code',
      render: (item) => <span>Invoice ({item.invoices_parent_item.invoice})</span>,
      width: 100,
    },
    {
      title: 'Invoice Date',
      key: 'invoice-date',
      render: (item) => formatDate(item.invoices_parent_item.createdAt, user.timezone),
      width: 240,
    },
    { title: 'TruckSync Records', key: 'bols', render: (item) => item.invoices_parent_item.bols, width: 100 },
    { title: 'CIs', key: 'inspections', render: (item) => item.invoices_parent_item.inspections, width: 100 },
    { title: 'WAIRE', key: 'waires', render: (item) => item.invoices_parent_item.waires, width: 100 },
    {
      title: 'Transactions',
      key: 'transactions',
      render: (item) =>
        item.invoices_parent_item.bols + item.invoices_parent_item.inspections + item.invoices_parent_item.waires,
      width: 140,
    },
    { title: 'Overage', key: 'overage', render: (item) => item.invoices_parent_item.overage, width: 100 },
    {
      title: 'Transaction Fee',
      key: 'transaction-fee',
      render: (item) => `$${Number(item.invoices_parent_item.transactionFee).toFixed(2)}`,
      width: 180,
    },
    {
      title: 'Overage Fee',
      key: 'overage-fee',
      render: (item) => `$${Number(item.invoices_parent_item.overageFee).toFixed(2)}`,
      width: 180,
    },
    {
      title: 'Total',
      key: 'total',
      render: (item) =>
        `$${Number(item.invoices_parent_item.transactionFee + item.invoices_parent_item.overageFee).toFixed(2)}`,
      width: 100,
    },
  ];

  const downloadReport = () => {
    if (!selectedRows.length) {
      toast.error('Please select a row to download', TOAST_CONFIG);
      return;
    }

    const workbook = XLSX.utils.book_new();

    for (const selectedRow of selectedRows) {
      const invoice = data.find((item) => item._id === selectedRow);

      const transactionTotal = Number(invoice.invoices.reduce((acc, curr) => acc + curr.transactionFee, 0)).toFixed(2);
      const overageTotal = Number(invoice.invoices.reduce((acc, curr) => acc + curr.overageFee, 0)).toFixed(2);

      const worksheet = XLSX.utils.json_to_sheet([
        ...invoice.invoices.map((item) => [
          item.locationName,
          item.bols,
          item.inspections,
          item.waires,
          item.bols + item.inspections + item.waires,
          item.overage,
          item.transactionFee,
          item.overageFee,
          item.transactionFee + item.overageFee,
        ]),
        ['Total', '', '', '', '', '', transactionTotal, overageTotal, transactionTotal + overageTotal],
      ]);

      const invoiceDate = moment(invoice.invoices_parent_item.createdAt).format('YYYY-MM-DD');
      XLSX.utils.book_append_sheet(
        workbook,
        worksheet,
        `${user?.company}-${invoiceDate}-INVOICE-${invoice.invoices_parent_item.invoice}`,
      );

      XLSX.utils.sheet_add_aoa(worksheet, [header]);
    }

    XLSX.writeFile(workbook, `Babaco Fulfillment - Invoice - ${user?.company} WAIRE.xlsx`, { compression: true });
  };

  const renderSummary = (items) => {
    let bols = 0,
      inspections = 0,
      overage = 0,
      overageFee = 0,
      transactions = 0,
      transactionFee = 0,
      waires = 0,
      total = 0;

    items.forEach(({ invoices_parent_item }) => {
      bols += invoices_parent_item.bols;
      inspections += invoices_parent_item.inspections;
      overage += invoices_parent_item.overage;
      overageFee += invoices_parent_item.overageFee;
      transactions += invoices_parent_item.bols + invoices_parent_item.inspections + invoices_parent_item.waires;
      transactionFee += invoices_parent_item.transactionFee;
      waires += invoices_parent_item.waires;
      total += invoices_parent_item.transactionFee + invoices_parent_item.overageFee;
    });

    return (
      <Table.Summary fixed={'bottom'}>
        <Table.Summary.Row>
          <Table.Summary.Cell index={0} colSpan={2}></Table.Summary.Cell>
          <Table.Summary.Cell index={1} colSpan={1}>
            Total
          </Table.Summary.Cell>
          <Table.Summary.Cell index={0} colSpan={1}></Table.Summary.Cell>
          <Table.Summary.Cell index={2} colSpan={1}>
            {bols}
          </Table.Summary.Cell>
          <Table.Summary.Cell index={3} colSpan={1}>
            {inspections}
          </Table.Summary.Cell>
          <Table.Summary.Cell index={8} colSpan={1}>
            {waires}
          </Table.Summary.Cell>
          <Table.Summary.Cell index={7} colSpan={1}>
            {transactions}
          </Table.Summary.Cell>
          <Table.Summary.Cell index={5} colSpan={1}>
            {overage}
          </Table.Summary.Cell>
          <Table.Summary.Cell index={7} colSpan={1}>
            ${Number(transactionFee).toFixed(2)}
          </Table.Summary.Cell>
          <Table.Summary.Cell index={6} colSpan={1}>
            ${Number(overageFee).toFixed(2)}
          </Table.Summary.Cell>
          <Table.Summary.Cell index={9} colSpan={1}>
            ${Number(total).toFixed(2)}
          </Table.Summary.Cell>
        </Table.Summary.Row>
      </Table.Summary>
    );
  };

  return (
    <div>
      <Button
        startIcon={<DownloadOutline />}
        type="button"
        variant="text"
        color="primary"
        onClick={downloadReport}
        style={{ paddingBottom: 10, textTransform: 'initial', color: '#5572EA' }}
      >
        Download Report
      </Button>

      <Table
        scroll={{ y: window.innerHeight / 2 }}
        className="header"
        columns={columns}
        expandable={{ expandedRowRender: (item) => expandedRowRender(item.invoices || []) }}
        dataSource={data}
        pagination={false}
        bordered={false}
        rowKey={(item) => item._id}
        size="middle"
        summary={renderSummary}
      />
    </div>
  );
};

export default BillingByInvoice;
