import React, { useContext, useEffect, useState } from 'react';
import {
  Button,
  FormControl,
  FormGroup,
  makeStyles,
  MenuItem,
  MenuProps,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import { Col, Row } from 'reactstrap';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import FormComponent from '../../components/FormComponent';
import { AuthContext } from '../../auth';
import { BabacoTheme } from '../../styles/theme';
import Loader from '../../components/Loader';
import { patch } from '../../utils/axios';

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%', // Fix IE 11 issue.
  },
  rightCol: {
    paddingLeft: `${theme.spacing(6)}px`,
    [theme.breakpoints.down('md')]: {
      paddingLeft: `${theme.spacing(3)}px`,
    },
  },
  formLabel: {},
  containerSpecific: {
    padding: BabacoTheme.spacing.sm,
  },
  formWrapper: {
    border: '1px solid #cccccc',
    padding: BabacoTheme.spacing.md,
  },
  timeframeInputs: {
    display: 'flex',
    width: '100%',
    // width: 'fit-content',
  },
  timeframeInputBox: {
    margin: '0 0 0 16px',
    width: '120px',
  },
  customList: {
    display: 'flex',
    marginBottom: '2rem',
  },
  selectedBubble: {
    borderRadius: '6px',
    backgroundColor: '#EEEEEE',
    border: '1px solid #707070',
    padding: '6px 12px',
    marginBottom: '6px',
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {},
  colElement: {
    '&:not(:first-child)': {
      marginLeft: '1.5rem',
    },
  },
  menuItem: {
    backgroundColor: 'transparent !important',
    display: 'block',
    padding: '6px',
    '&.Mui-selected': {
      '& .MuiChip-root': {
        backgroundColor: '#D6E2D6',
      },
    },
  },
  menuList: {
    display: 'flex',
    flexWrap: 'wrap',
    width: 'min-content',
    padding: '6px 16px',
  },
  timeOptionsList: {
    display: 'flex',
  },
  timeOptionsListItem: {
    marginTop: '1rem !important',
    '&:not(:first-of-type)': {
      marginLeft: '1rem',
    },
  },
  titleCol: {
    display: 'flex',
  },
  titleColArrow: {
    // marginLeft: 'auto',
  },
  submitBtn: {
    width: '100%',
  },
  flexSelect: {
    '& ul': {
      display: 'flex',
      flexWrap: 'wrap',
      width: 'min-content',
    },
  },
}));

const timeframeOptions = [
  {
    value: 'never',
    label: 'Never',
  },
  {
    value: '24',
    label: '24 Hours',
  },
  {
    value: '48',
    label: '48 Hours',
  },
  {
    value: '72',
    label: '72 Hours',
  },
  {
    value: '96',
    label: '4 Days',
  },
  {
    value: '120',
    label: '5 Days',
  },
  {
    value: '144',
    label: '6 Days',
  },
  {
    value: '168',
    label: '1 Week',
  },
  {
    value: 'customTimeframe',
    label: 'Custom Time Frame',
  },
];

const PageAlertSettings = () => {
  const classes = useStyles();
  const { user, setUser } = useContext(AuthContext);

  const [apiError, setApiError] = useState(null);
  const [loading, setLoading] = useState(false);

  const { handleSubmit, control, watch, reset, setValue } = useForm({
    defaultValues: {
      timeframe: 'never',
      customTimeframe: {
        weeks: '0',
        days: '0',
        hours: '0',
      },
      customPairs: [
        { outbound: '', inbound: [], timeframe: 'never', customTimeframe: { weeks: 0, days: 0, hours: 0 } },
      ],
    },
  });

  const { fields } = useFieldArray({
    control,
    name: 'customPairs',
  });
  const watchAll = watch();
  const watchPairs = watch('customPairs');

  const controlledFields = fields.map((field, index) => {
    return {
      ...field,
      ...watchPairs[index],
    };
  });

  const MenuProps: Partial<MenuProps> = {
    getContentAnchorEl: null,
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'left',
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'left',
    },
  };

  const parseCustomPairs = (customPairs) => {
    const finalPairs = [];

    customPairs.forEach((pair, i) => {
      if (validateCustomPair(pair, i, false)) {
        finalPairs.push(pair);
      }
    });

    return finalPairs;
  };

  const validateCustomPair = (pair, i, showError = true) => {
    let passed = true;

    if (!pair.inbound || !pair.outbound) {
      if (showError) {
        toast.error(`Please specify outbound and inbound locations for rule ${i + 1}.`, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }

      passed = false;
    }

    if (
      pair.timeframe === 'customTimeframe' &&
      pair.customTimeframe.weeks == 0 &&
      pair.customTimeframe.days == 0 &&
      pair.customTimeframe.hours == 0
    ) {
      if (showError) {
        toast.error(`When using a custom timeframe please specify explicit time for rule ${i + 1}.`, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
      passed = false;
    }

    return passed;
  };

  const validateFields = (fields) => {
    let passed = true;
    const { timeframe, customTimeframe, customPairs } = fields;
    if (
      timeframe === 'customTimeframe' &&
      customTimeframe.weeks == 0 &&
      customTimeframe.days == 0 &&
      customTimeframe.hours == 0
    ) {
      toast.error('When using a custom timeframe please specify explicit time.', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      passed = false;
    }

    // customPairs.forEach((pair, i) => {
    //   if (i !== customPairs.length - 1) {
    //     passed = validateCustomPair(pair, i);
    //   }
    // });

    // // check for duplicate pairings
    // const valueArr = customPairs.map((item) => {
    //   return `${item.outbound}-${(item.inbound || []).sort().join('-')}`;
    // });

    // const isDuplicate = valueArr.some((item, idx) => {
    //   return valueArr.indexOf(item) != idx;
    // });

    // if (isDuplicate) {
    //   toast.error('Cannot use the same outbound-inboud pair twice.', {
    //     position: 'top-right',
    //     autoClose: 5000,
    //     hideProgressBar: false,
    //     closeOnClick: true,
    //     pauseOnHover: true,
    //     draggable: true,
    //     progress: undefined,
    //   });
    // }

    // passed = !isDuplicate;

    // if (!passed) {
    //   toast.error('Validation failed, please check all pairings are correct.', {
    //     position: 'top-right',
    //     autoClose: 5000,
    //     hideProgressBar: false,
    //     closeOnClick: true,
    //     pauseOnHover: true,
    //     draggable: true,
    //     progress: undefined,
    //   });
    // }

    return passed;
  };

  const handleSave = async (fields) => {
    setApiError(null);

    if (!validateFields(fields)) return;

    setLoading(true);

    try {
      const { timeframe, customTimeframe } = fields;

      const { data } = await patch(`/alerts`, { time: timeframe === 'customTimeframe' ? customTimeframe : timeframe });

      setUser({ ...user, time: data.alert });

      toast.success('Preferences saved', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (error) {
      setApiError(error);

      toast.error(error?.response?.data?.message, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

    setLoading(false);
  };

  const setDefaultAlertValues = () => {
    if (typeof user?.time === 'string') {
      setValue('timeframe', user?.time);
    } else if (typeof user?.time === 'object') {
      setValue('timeframe', 'customTimeframe');
      setValue('customTimeframe', user?.time);
    }
  };

  useEffect(() => {
    setDefaultAlertValues();
  }, []);

  return (
    <Loader isLoading={loading}>
      <Row>
        <Col md={{ size: 5 }}>
          <FormComponent
            className={classes.form}
            hasBorder={false}
            hasPadding={false}
            onSubmit={handleSubmit(handleSave)}
            error={apiError?.message}
          >
            <div className="title-section">
              <Typography variant="h5" className="mb-4">
                <b>Time-Based Alert Settings</b>
              </Typography>
              <Typography variant="subtitle1" className="mt-4">
                Time-based Alerts are automatically created when the Inbound portion of an Outbound-to-Inbound Record
                has not been received within a specified period of time.
              </Typography>
            </div>
            <div className="default-section mb-4">
              <Typography className="mt-5 mb-1">
                <strong>Set the Duration between Outbound and Inbound</strong>
              </Typography>
              <Typography className="mb-5" style={{ fontSize: '13px' }}>
                <em>Receive an Alert if the duration between Outbound and Inbound exceeds the following:</em>
              </Typography>

              <FormGroup row>
                <Col lg={{ size: 12 }}>
                  <div className={classes.timeframeInputs}>
                    <Controller
                      name={'timeframe'}
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <FormControl variant="outlined" component="fieldset" margin="normal">
                          <Select displayEmpty value={value} onChange={onChange} MenuProps={MenuProps}>
                            {timeframeOptions.map((option, i) => (
                              <MenuItem key={i} value={option.value}>
                                {option.label}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      )}
                    />
                    {watchAll.timeframe === 'customTimeframe' && (
                      <>
                        <Controller
                          defaultValue={'0'}
                          name="customTimeframe.weeks"
                          control={control}
                          render={({ field: { onChange, value }, fieldState: { error } }) => {
                            return (
                              <TextField
                                className={classes.timeframeInputBox}
                                InputProps={{ inputProps: { min: 0, max: 10 } }}
                                autoFocus
                                onChange={onChange}
                                error={!!error}
                                helperText={error ? error.message : null}
                                value={value}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                id="customTimeframeWeeks"
                                name="customTimeframeWeeks"
                                type="number"
                                label="Weeks"
                              />
                            );
                          }}
                        />
                        <Controller
                          defaultValue={'0'}
                          name="customTimeframe.days"
                          control={control}
                          render={({ field: { onChange, value }, fieldState: { error } }) => {
                            return (
                              <TextField
                                className={classes.timeframeInputBox}
                                InputProps={{ inputProps: { min: 0, max: 6 } }}
                                autoFocus
                                onChange={onChange}
                                error={!!error}
                                helperText={error ? error.message : null}
                                value={value}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                id="customTimeframeDays"
                                name="customTimeframeDays"
                                type="number"
                                label="Days"
                              />
                            );
                          }}
                        />
                        <Controller
                          defaultValue={'0'}
                          name="customTimeframe.hours"
                          control={control}
                          render={({ field: { onChange, value }, fieldState: { error } }) => {
                            return (
                              <TextField
                                className={classes.timeframeInputBox}
                                InputProps={{ inputProps: { min: 0, max: 23 } }}
                                autoFocus
                                onChange={onChange}
                                error={!!error}
                                helperText={error ? error.message : null}
                                value={value}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                id="customTimeframeHours"
                                name="customTimeframeHours"
                                type="number"
                                label="Hours  "
                              />
                            );
                          }}
                        />
                      </>
                    )}
                  </div>
                </Col>
              </FormGroup>
            </div>

            <Button type="submit" fullWidth variant="contained" color="primary" className={classes.submitBtn}>
              APPLY
            </Button>
          </FormComponent>
        </Col>
      </Row>
    </Loader>
  );
};

export default PageAlertSettings;
