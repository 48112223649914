import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/styles';
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { fetchCustomerSummary } from '../../api/customer';
import { QUERY_KEYS } from '../../constants';
import { toastError } from '../../utils/toastError';
import Warehouses from './Warehouses';
import UpcomingBilling from './UpcomingBilling';
import BillingByLocation from './BillingByLocation';
import BillingByInvoice from './BillingByInvoice';
import User from './User';
import Users from './Users';

const useStyles = makeStyles(() => ({
  header: {
    marginBottom: '40px',
  },
  heading: {
    display: 'block',
    whiteSpace: 'nowrap',
    fontSize: '20px',
    fontWeight: 'bold',
  },
  stats: {
    paddingTop: '6px',
    fontSize: '14px',
  },
  waireText: {
    paddingTop: '6px',
    fontSize: '14px',
    display: 'inline-block',
  },
  waireContainer: {
    display: 'inline-block',
  },
  waireCheckbox: {
    display: 'inline-block',
    padding: 0,
    marginRight: '4px',
  },
}));

const Customer = () => {
  const classes = useStyles();

  const params: any = useParams();
  const history = useHistory();

  const { search } = useLocation();
  const tab = Object.fromEntries(new URLSearchParams(search)).tab;

  const [tabIndex, setTabIndex] = useState(tab ? Number(tab) : 0);

  const { data: summary } = useQuery({
    queryKey: [QUERY_KEYS.customer_summary, params.id],
    queryFn: () => fetchCustomerSummary(params.id),
    onError: toastError,
  });

  const onTabSelect = (tab) => {
    setTabIndex(tab);
    history.push(`/customers/${params.id}?tab=${tab}`);
  };

  const headerText = (
    <div className={classes.header}>
      <Typography className={classes.heading}>Customer - {summary?.name}</Typography>
    </div>
  );

  return (
    <Box>
      {headerText}

      <Tabs selectedIndex={tabIndex} onSelect={onTabSelect}>
        <TabList>
          <Tab>Warehouses</Tab>
          <Tab>Users</Tab>
          <Tab>Create New User</Tab>
          <Tab>Billing (Upcoming)</Tab>
          <Tab>Billing (History) - Locations</Tab>
          <Tab>Billing (History) - Invoices</Tab>
        </TabList>

        <TabPanel>
          <Warehouses />
        </TabPanel>
        <TabPanel>
          <Users />
        </TabPanel>
        <TabPanel>
          <User />
        </TabPanel>
        <TabPanel>
          <UpcomingBilling />
        </TabPanel>
        <TabPanel>
          <BillingByLocation />
        </TabPanel>
        <TabPanel>
          <BillingByInvoice />
        </TabPanel>
        <TabPanel>
          <User />
        </TabPanel>
      </Tabs>
    </Box>
  );
};

export default Customer;
