import React, { useContext, useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { toast } from 'react-toastify';

import { AuthContext } from '../../auth';
import { Container, Row, Col } from 'reactstrap';
import FormComponent from '../../components/FormComponent';
import Loader from '../../components/Loader';
import { post } from '../../utils/axios';
import { getRedirectUrlBasedOnFeaturesAvailable } from '../../utils/helpers';

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(16),
  },
  form: {
    width: '100%', // Fix IE 11 issue.
  },
  forgotPassword: {
    marginTop: theme.spacing(2),
  },
}));

const ResetPassword = ({ history }: RouteComponentProps) => {
  const classes = useStyles();
  const { user } = useContext(AuthContext);
  const { handleSubmit, control } = useForm();
  const [apiError, setApiError] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleReset = async (fields) => {
    setIsSubmitting(true);
    setApiError(null);

    const { email } = fields;

    try {
      await post('/user/reset-password', { email });

      history.push('/login');

      toast.info(`Please check your email for the link to reset your password.`, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (error) {
      setIsSubmitting(false);
      setApiError(error?.response?.data?.message || error?.response?.data?.errors?.[0]?.msg);

      toast.info(
        error?.response?.data?.message ||
          `${error?.response?.data?.errors?.[0]?.msg}: ${error?.response?.data?.errors?.[0]?.value}`,
        {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        },
      );
    }
  };

  if (user?.emailVerified) {
    return <Redirect to={getRedirectUrlBasedOnFeaturesAvailable(user?.features)} />;
  }

  return (
    <Container className={classes.container}>
      <Row>
        <Col md={{ size: 8, offset: 2 }}>
          <Row>
            <Col lg={4}>
              <p>
                <strong>
                  Babaco<i>Fullfillment</i>
                </strong>
                &nbsp; allows you to digitize Bill of Lading and provide proof of secure delivery by sending you updates
                in the field and confirming your shipments get to their destinations.
              </p>
            </Col>
            <Col lg={{ size: 6, offset: 2 }}>
              <FormComponent className={classes.form} onSubmit={handleSubmit(handleReset)} error={apiError}>
                <Controller
                  name="email"
                  control={control}
                  defaultValue=""
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <TextField
                      autoFocus
                      onChange={onChange}
                      error={!!error}
                      helperText={error ? error.message : null}
                      value={value}
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      id="email"
                      label="Email Address"
                      name="email"
                      autoComplete="email"
                    />
                  )}
                  rules={{
                    required: 'Email required',
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                      message: 'Enter a valid e-mail address',
                    },
                  }}
                />

                <Loader isLoading={isSubmitting} small>
                  <Button type="submit" fullWidth variant="contained" color="primary" className="mt-2">
                    SEND EMAIL
                  </Button>
                </Loader>
              </FormComponent>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default ResetPassword;
