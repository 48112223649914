import React from 'react';
import { Box, Checkbox, Divider, FormHelperText, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import { Controller, useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { Col } from 'reactstrap';
import { queryClient } from '../../api/queryClient';
import FormComponent from '../../components/FormComponent';
import Loader from '../../components/Loader';
import { QUERY_KEYS, TOAST_CONFIG } from '../../constants';
import { toastError } from '../../utils/toastError';
import { toast } from 'react-toastify';
import { createManager, updateManager } from '../../api/manager';
import { get } from '../../utils/axios';
import { BabacoTheme } from '../../styles/theme';

const useStyles = makeStyles(() => ({
  form: {
    width: '100%',
  },
  label: {
    marginBottom: 10,
    fontSize: 12,
    color: '#000000',
  },
  input: {
    marginBottom: 20,
  },
  checkboxes: {
    marginTop: 40,
    marginBottom: 40,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  checkbox: {
    display: 'flex',
    alignItems: 'center',
  },
  empty: {
    height: 19,
    width: 19,
    border: '2px solid rgba(118, 118, 118)',
    cursor: 'pointer',
    marginLeft: 10,
    marginRight: 15,
    marginTop: -4,
  },
}));

const Manager = (props) => {
  const classes = useStyles();

  const managerId = props.match.params?.id;

  const { data: manager, isLoading: managerLoading } = useQuery({
    queryKey: [QUERY_KEYS.managers, managerId],
    queryFn: async () => {
      const { data } = await get(`/managers/${managerId}`);
      return data;
    },
    enabled: !!managerId,
    placeholderData: {},
    onError: toastError,
  });

  const { data: locations, isLoading: locationsLoading } = useQuery({
    queryKey: QUERY_KEYS.all_locations,
    queryFn: async () => {
      const { data } = await get('/locations/all');
      return data;
    },
    placeholderData: [],
  });

  const { handleSubmit, control, reset, setValue } = useForm({
    values: {
      name: manager?.firstName || '',
      email: manager?.email || '',
      features: manager?.features || {
        bol: false,
        waire: false,
        logistic: false,
        inspection: false,
      },
      disabled: manager?.disabled || false,
      locations: manager?.locations
        ? locations.reduce((acc, curr) => {
            acc[curr._id] = !!manager.locations.find((location) => location === curr._id);
            return acc;
          }, {})
        : locations.reduce((acc, curr) => {
            acc[curr._id] = false;
            return acc;
          }, {}),
    },
  });

  const onSuccess = () => {
    reset();
    queryClient.resetQueries({ queryKey: [QUERY_KEYS.managers] });
    props.history.push('/managers');
  };

  const { mutate: create, isLoading: createLoading } = useMutation((fields) => createManager(fields), {
    onError: toastError,
    onSuccess: () => {
      toast.success('Manager created successfully', TOAST_CONFIG);
      onSuccess();
    },
  });

  const { mutate: update, isLoading: updateLoading } = useMutation((fields) => updateManager(managerId, fields), {
    onError: toastError,
    onSuccess: () => {
      toast.success('Manager updated successfully', TOAST_CONFIG);
      onSuccess();
    },
  });

  if (managerLoading || locationsLoading) return <Loader isLoading />;

  return (
    <div>
      <Typography variant="h4" style={{ fontWeight: 'bold', fontSize: '22px', marginBottom: 50 }}>
        Team Management / {managerId ? 'Update' : 'Create New'} Location Manager
      </Typography>

      <Col md={{ size: 5, offset: 0 }}>
        <FormComponent className={classes.form} onSubmit={handleSubmit(managerId ? (update as any) : (create as any))}>
          <Controller
            name="name"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <div className={classes.input}>
                <InputLabel htmlFor="name" className={classes.label} style={{ fontWeight: 'bold' }}>
                  *Name
                </InputLabel>
                <TextField
                  onChange={onChange}
                  error={!!error}
                  helperText={error ? error.message : null}
                  value={value}
                  variant="outlined"
                  fullWidth
                  name="name"
                  id="name"
                  required
                />
              </div>
            )}
            rules={{ required: 'Name is required' }}
          />

          <Controller
            name="email"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <div className={classes.input} style={{ marginBottom: 40 }}>
                <InputLabel htmlFor="email" className={classes.label} style={{ fontWeight: 'bold' }}>
                  *Email
                </InputLabel>
                <TextField
                  autoFocus
                  onChange={onChange}
                  error={!!error}
                  helperText={error ? error.message : null}
                  value={value}
                  variant="outlined"
                  fullWidth
                  id="email"
                  name="email"
                  required
                  autoComplete="email"
                />
                <FormHelperText style={{ color: '#000', fontSize: 14 }}>
                  New Location Manager will receive an email with password and verification
                </FormHelperText>
              </div>
            )}
            rules={{
              required: 'Email required',
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                message: 'Enter a valid e-mail address',
              },
            }}
          />

          <Divider />

          {managerId && (
            <div className={classes.checkboxes}>
              <Typography variant="h4" style={{ fontWeight: 'bold', fontSize: 14, marginBottom: 10 }}>
                Enable/Disable Manager
              </Typography>

              <Controller
                name="disabled"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <div className={classes.checkbox}>
                    <Checkbox id="disabled" name="disabled" checked={value} onChange={onChange} />
                    <InputLabel htmlFor="disabled" className={classes.label} style={{ marginTop: 10 }}>
                      DISABLED
                    </InputLabel>
                  </div>
                )}
              />
            </div>
          )}

          <Divider />

          <div className={classes.checkboxes}>
            <Typography variant="h4" style={{ fontWeight: 'bold', fontSize: 14, marginBottom: 30 }}>
              Assign Manager with Permissions to Babaco Fulfillment Modules
            </Typography>

            <Controller
              name="features.waire"
              control={control}
              render={({ field: { onChange, value } }) => (
                <div className={classes.checkbox}>
                  <Checkbox id="features.waire" name="features.waire" checked={value} onChange={onChange} />
                  <InputLabel htmlFor="features.waire" className={classes.label} style={{ marginTop: 10 }}>
                    WAIRE
                  </InputLabel>
                </div>
              )}
            />
          </div>

          <Divider />

          <div className={classes.checkboxes}>
            <Typography variant="h4" style={{ fontWeight: 'bold', fontSize: 14, marginBottom: 30 }}>
              Assign Manager to Specific Locations
            </Typography>

            {locations.map((location) => (
              <Controller
                key={location._id}
                name={`locations.${location._id}`}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <div className={classes.checkbox}>
                    <Box
                      id={`locations.${location._id}`}
                      className={classes.empty}
                      style={{
                        backgroundColor: value ? BabacoTheme.colors.yellow : '#ffffff',
                      }}
                      onClick={() => {
                        setValue(`locations.${location._id}`, !value);
                      }}
                    />
                    <InputLabel
                      htmlFor={`locations.${location._id}`}
                      className={classes.label}
                      style={{ marginTop: 10 }}
                    >
                      {location.firstName}
                    </InputLabel>
                  </div>
                )}
              />
            ))}
          </div>

          <Loader isLoading={createLoading || updateLoading} small>
            <Button type="submit" fullWidth variant="contained" color="primary" style={{ marginBottom: 20 }}>
              {managerId ? 'UPDATE' : 'CREATE'}
            </Button>
          </Loader>
        </FormComponent>
      </Col>
    </div>
  );
};

export default Manager;
