import React, { ReactElement, useEffect, useState } from 'react';
import { queryClient } from '../api/queryClient';
import { fetchUserDetails } from '../api/user';
import { auth } from '../firebase';
import { toast } from 'react-toastify';
import { TOAST_CONFIG } from '../constants';

interface AuthContextI {
  user: any;
  setUser: any;
  logout: () => Promise<any>;
}

export const AuthContext = React.createContext<AuthContextI>(null);

interface ComponentProps {
  children: ReactElement;
}

export const AuthProvider = ({ children }: ComponentProps) => {
  const [user, setUser] = useState(null);
  const [pending, setPending] = useState(true);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (fbUser) => {
      try {
        if (fbUser) {
          const data = await fetchUserDetails();

          setUser(data);
        } else {
          setUser(null);
        }

        setPending(false);
      } catch (error) {
        await auth.signOut();
        toast.info(`Please contact your admin for privileges`, TOAST_CONFIG);

        setUser(null);
        setPending(false);
      }
    });

    return unsubscribe;
  }, []);

  const logout = () => {
    queryClient.clear();
    return auth.signOut();
  };

  return pending ? null : (
    <AuthContext.Provider
      value={{
        user,
        setUser,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
